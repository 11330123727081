import React from 'react';
import { Route }        from "react-router-dom";
import OnlineInventory  from '../../pages/OnlineInventory/OnlineInventory';
import InventoryHistory from '../../pages/InventoryHistory/InventoryHistory';
import Evaluadores      from '../../pages/evaluadores/evaluadores';
import asignarEvaluadores      from '../../pages/asignarEvaluadores/asignarEvaluadores';
import generarRubricas      from '../../pages/generarRubricas/generarRubricas';
import InventoryLeftOver from '../../pages/InventoryLeftOver/InventoryLeftOver';
import InventoryNotifications from '../../pages/InventoryNotifications/InventoryNotifications';
import AssetSurplus from '../../pages/assetSurplus/assetSurplus';
import RegisterAssetSurplus from '../../pages/assetRegisterSurplus/assetRegisterSurplus';
import assetListSurplus from '../../pages/assetListSurplus/assetListSurplus';
import MissingInventory from '../../pages/MissingsAssets/MissingInventory';
import ReportMissingInventory from '../../pages/ReportMissing/ReportMissingInventory';
 

export default function Routers() {
  return (
    <React.Fragment>

          {/* <Route exact path="/">
            <StartMenu/>
          </Route>  */}
          <Route path="/ReportMissings" exact component={ReportMissingInventory}/>
          <Route path="/Missings" exact component={MissingInventory}/>
          <Route path="/Surpluses" exact component={assetListSurplus}/>
          <Route path="/registerSurplusAsset" exact component={RegisterAssetSurplus}/>
          <Route path="/surplusAsset" exact component={AssetSurplus}/>
          <Route path="/tonotifys" exact component={InventoryNotifications}/>
          <Route path="/toleftover" exact component={InventoryLeftOver}/>
          <Route path="/toreport" exact component={InventoryHistory}/>
          <Route path="/crear-evaluadores" exact component={Evaluadores} />
          <Route path="/asignar-evaluadores" exact component={asignarEvaluadores} />
          <Route path="/generar-rubricas" exact component={generarRubricas} />
          <Route path="/" exact component={OnlineInventory}/>
     
    </React.Fragment>
  );
}