import React, { useState, useRef } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

// Material UI Components
import { ButtonBase, Chip, Grid, IconButton, Paper, Radio, TextField, Typography, withStyles } from '@material-ui/core';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AddAlert from '@material-ui/icons/AddAlert';
import NotificationsActiveRounded from '@material-ui/icons/NotificationsActiveRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { faBarcode, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faPuzzlePiece, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  styled, List, ListItem, ListItemIcon, ListItemText, Button, FormControlLabel,
  Collapse, ListItemButton
} from '@material-ui/core';

import { Alert, Autocomplete } from '@material-ui/lab';

import { allAssetForPDF, fetchForImpresion } from '../../redux/actions/assignedAssetActions';
import { pdf } from "@react-pdf/renderer";
import { fetchRazonReimpresionPlaca, getIdOptionSUSIList, getNameOptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { fetchInventoryOfficers } from '../../redux/actions/SUSIListsActions'; //TLA
import { fetchDataUser }          from '../../redux/actions/dataUserActions'; //TLA 
import { DocumentService } from '../../services/generateDocumentService';

import '../../assets/css/material-grid.css';
import { Editor } from '@tinymce/tinymce-react';
import { sendMail } from '../../redux/actions/sendMailActions';
import Checkbox from '@material-ui/core/Checkbox';

import fiscaliapng from "../../assets/img/logotipo_acho.png";
import fiscaliapng2 from "../../assets/img/acho_blanco_responsive.png";

import { REACT_APP_VERSION } from "../../config";
import { RESOLUCION_INVENTARIO } from "../../config";
import { useDispatch } from 'react-redux';
import { fetchTotalMissings } from '../../redux/actions/missingActions';
import { useEffect } from 'react';
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import { fetchEvaluations } from '../../redux/actions/evaluationsActions';
import { fetchScore } from '../../redux/actions/scoreActions';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import EditIcon from '@material-ui/icons/Edit';
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import Popover from "@material-ui/core/Popover";

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.mode == 'dark' ? '#394b59' : 'white',
  border: '3px solid #9d9ea5',
  backgroundImage:
    theme.palette.mode == 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode == 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z' fill='%236ce1f5'/%3E%3C/svg%3E\")",
    content: '""',
  },
  border: '3px solid #6ce1f5',
});





function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}


const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    },
    padding: 0
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const MainListItems2 = (props) => {
  /* const [expanded, setExpanded] = useState('panel1');
  const [errorCreateInventory, setErrorCreateInventory] = useState();
  const [inventoryPresent, setInventoryPresent] = useState(false);
  const [showButtonsCreate, setShowButtonsCreate] = useState(true);
  const [savingInventory, setSavingInventory] = useState(false);
  const [checklistSaved, setChecklistSaved] = useState('');
  const [checklistSavedDate, setChecklistSavedDate] = useState(''); 
  const [showDlgCreateChecklist, setShowDlgCreateChecklist] = useState(false);
  */

  const perfil = props.inventoryOfficers
  const userrole =  window.sessionStorage.getItem('userrole');
  const tipos  = Object.entries(props.rePrintReasons).length
  const [changeType, setChangeType] = useState(null)
  const [changeTypeError, setChangeTypeError] = useState(null);
  const [open, setOpen] = useState(false)
  const [email, setEmail] = React.useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA)
  const [ccmail, setCcEmail] = React.useState([])
  const [placa, setPlaca] = React.useState('')
  const [serie, setSerie] = React.useState('')
  const [asuntoMail, setAsuntoMail] = React.useState(`Solicitud Renovación de plaqueta adhesiva`)
  const [sended, setSended] = React.useState(false)
  const [emailAlert, setEmailAlert] = React.useState('')
  const [finded, setFinded] = React.useState(false)
  const [openPrint, setOpenPrint] = useState(false);
  const [enableFeaturesPrint, setEnableFeaturesPrint] = React.useState(false)
  const [assetFind, setAssetFind] = React.useState({
    placa: '',
    serie: '',
    error: false
  })

  const dispatch = useDispatch();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const pathname = useLocation().pathname;
  const editorRef = useRef(null);


//*************************************************/
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClickSubmenu = () => {
  setOpenSubMenu(!openSubMenu);
};

const handleClose = () => {
  setAnchorEl(null);
};
//**************************************** */


  let headChs = '';
  if (assetFind && assetFind.location != null) {
    headChs = getnameLocation(assetFind.location_id);
  }
  const classes = useStyles();
  const [headquarterChs, setHeadQuarterChs] = useState(headChs);
  const [sendMailState, setSendMailState] = useState(false);
  
  
  const getURLAsset = (assetFile) => {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  };

  const toggleDialog = () => {
    const currentState = open;
    if (!open) {
      setAssetFind({
        placa: '',
        serie: '',
        error: false
      });
      setFinded(false);
      setSended(false);
      setEmailAlert('');
      setCcEmail([]);
      setChangeType(null)
      setChangeTypeError(null)
      setSendMailState(false)
    }
    setOpen(!currentState);

  };

  const toggleDialogPrint = () => {
    const currentState = openPrint;
    setOpenPrint(!currentState);
  };

  const getnameLocation = (idLocation) => {
    let dataname = props.locations.filter(t => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
  }

  const downloadPdf = async () => {
    let data = await allAssetForPDF(props.user.numero_documento, '', enableFeaturesPrint);
    if (data.length > 0) {
      var docInventario = await pdf(DocumentService.generateDocument(data.map(function (asset) {
        let novedad = asset.change_type_id == getIdOptionSUSIList('Faltante', props.changesTypes) ? ('FALTANTE (' + getNameOptionSUSIList(asset.missing_reason_id, props.missingReasons) + ')').toUpperCase() : asset.change_type_id == getIdOptionSUSIList('Verificado', props.changesTypes) ? 'VERIFICADO' : 'SIN NOVEDAD';
        let map = {
          serialBien: asset.serialBien,
          serieBien: asset.serieBien,
          placaBien: asset.placaBien,
          descripcionBien: asset.descripcionBien,
          estadoBien: props.stages.stages.find(stage => stage.code == asset.estadoBien).name,
          assetStatus: asset.estadoBienSUSI == getIdOptionSUSIList('Regular', props.assetsStatus),
          novedad: novedad,
          ubicacion: getnameLocation(asset.location_id),
          dependencia: asset.dependencia,
          lastCheck: asset.date_checklist,
          seccional: asset.nombreSeccional,
          funcionarioSolicitante: asset.funcionarioSolicitante,
          features: asset.features,
          responsable: props.user.fullName
        }
        return map;
      }), false, enableFeaturesPrint)).toBlob();
      var csvURL = window.URL.createObjectURL(docInventario);
      let tempLink = window.document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'FGN REPORTE DE INVENTARIO.pdf');
      // console.log('intenta descargar')
      tempLink.click();
    }
  }

  const downloadRes = () => {

      var pdfPath = process.env.PUBLIC_URL + RESOLUCION_INVENTARIO;
      let tempLink = window.document.createElement('a');
      tempLink.href = pdfPath;
      tempLink.download = '';
      //tempLink.setAttribute('download', 'RESOLUCION0-1166_2021.pdf');
      // console.log('intenta descargar')
      //console.log(tempLink)
      tempLink.click();
  }



  const send = async () => {
    //console.log('props.user: ',props.user);
    if (editorRef.current) {
      setSended(true)
      let params = {
        subject: asuntoMail + ` (${assetFind.placaBien})`,
        emailEnd: email,
      }
      let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      if (result) {
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };
  const templateText = () => {
    return `
      <p style="font-weight: 400; color: #5f6273 !important; font-size: 0.875rem !important">
        Señores Inventarios
        <br><br>
        Se informa que una vez consultada la información de serie 
        ${assetFind.serieBien == null ? 'no identificado' : assetFind.serieBien}, el sistema identificó la placa 
        ${assetFind.placaBien} a cargo de 
        ${props.user.fullName}
        <br><br>
        Se solicita acompañamiento para realizar la verificación física del bien, y definir si aplica "renovación de placa de código de barras" informada por ${changeType.name}, 
        para la posterior legalización.
        <br><br>
        ${props.user.fullName}
        <br><br>
        Cédula:${props.user.numero_documento}
        <br><br>
        ${props.dataUser.subDirLocDesc}
        <br><br>
        ${props.sectionalsUser.sectionals[1].name}
        <br><br>
        Correo electrónico: ${props.dataUser.person.email}
      </p>`;
  }
  const onValidate = async () => {
    let serieBusca='';
    if(placa != null && placa.length > 0)
       serieBusca='';
    else
       serieBusca = '%' + serie + '%';
    let find = await fetchForImpresion({ identification: props.user.numero_documento, placa: placa, serie: serieBusca });
    if (changeType != null || tipos==0) {
      if (find != null && find.length > 0) {
        setAssetFind({ ...find[0], error: false })
        setFinded(true)
      } else {
        setAssetFind({
          placa: '', serie: '', error: true
        })
      }
    } else {
      if(tipos>0) 
        { setChangeTypeError('Debe seleccionar un motivo de renovación de plaqueta adhesiva.')}
    }
  }

  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }
  
  const renderDialogPrint = () => {
    return (
      <div>
        <Dialog className='dialogReounded RoportInventory'
          fullWidth
          maxWidth={'sm'}
          open={openPrint}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className='dialogTttle' id="alert-dialog-title">Genere la Certificación</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <BpCheckbox
                    checked={enableFeaturesPrint}
                    onChange={e => setEnableFeaturesPrint(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Ultima"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="btnSecundary w-auto" onClick={toggleDialogPrint} color="primary">
              Cancelar
            </Button>
            <Button style={{ marginLeft: '0.5rem', width: 'auto' }} className="btnPrimary" onClick={downloadPdf} color="primary" autoFocus>
              Generar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const handleChangeType = (event) => {
    let optionData = props.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)
    setChangeType(optionData);
    setChangeTypeError(null);
  };

  const renderDialogMail = () => {
    var clase = !finded ? 'containerCorreoBienes1' : !sendMailState ? 'containerCardCorreoBien' : 'containerCorreoBienes2';

    return (
      <Dialog className={clase} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {emailAlert.length == 0 ?
          <>
            <DialogTitle className='dialogTttleReimpresión'>{"Consultar bienes para la renovación de placa adhesiva"}</DialogTitle>
            {!finded ?
              <>
                <DialogContent style={{ padding: '0 3rem' }}>
                  <label className='labelStyle' htmlFor='placa'>Placa</label>
                  <TextField style={{ marginBottom: '1rem' }} className='field' fullWidth id="placa" value={placa} onChange={(e) => setPlaca(e.target.value.trim())} variant="outlined" />

                  <p style={{ fontSize: '0.875ewm', color: '#5f6273', textAlign: 'center', margin: '0' }}>ó</p>

                  <label className='labelStyle' htmlFor='serie'>Serie</label>
                  <TextField className='field' fullWidth id="serie" value={serie} onChange={(e) => setSerie(e.target.value.trim())} variant="outlined" />

                  <br /><br />
                  <label className='labelStyle'>{"Motivo por el cual está solicitando la renovación de placa adhesiva"}</label>
                  <br /><br />

                  <ButtonBase className='ContainerButtonGroup'>
                    <Grid container direction='row'>
                      {props.rePrintReasons.length > 0 && props.rePrintReasons.map(transact => {
                        return (
                          <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                            <Grid item xs="auto" sm="auto" lg="auto">
                              <BlueRadio
                                checked={changeType != null && changeType.id == transact.id}
                                onChange={handleChangeType}
                                value={transact.id}
                                name={`${transact.id}`}
                              />
                            </Grid>
                            <Grid item xs sm lg="auto">
                              <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                            </Grid>
                          </Grid>)
                      })}
                    </Grid>
                  </ButtonBase>

                  {changeTypeError != null && <Alert className='alertaConsultaBienes' severity={'info'}>
                    {changeTypeError}
                  </Alert>}
                  {assetFind.error && <Alert className='alertaConsultaBienes' severity={'info'}>
                    No se encontro un bien con esta información
                  </Alert>}
                </DialogContent>

                <DialogActions className="actionsMailDialog">
                  <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                    Cancelar
                  </Button>
                  <Button className='btnPrimary' onClick={onValidate} color="primary" autoFocus>
                    Buscar
                  </Button>
                </DialogActions>
              </> : !sendMailState ?
                <>
                  <DialogContent>
                    <Paper className='papperSurplus'>
                      <Grid container className='contenedorAssetGridMayor'>
                        <Grid className='contenedorAssetGrid' container item xs={12} sm={12}>
                          <Grid className='columnaLogo' container item xs sm={3} lg xl style={{ justifyContent: 'center', paddingRight:'0.5rem' }} direction="column">
                            <IconButton className="asset_icon" >
                              <img className={classes.img} alt="Imagen Bien" src={getURLAsset(assetFind.category_icon)} />
                            </IconButton>
                            <Typography align="center" display="block" gutterBottom className='descripcionBien'> {assetFind.descripcionBien}</Typography>
                          </Grid>

                          <Grid className='columnaInfo' container item xs={12} sm={9} lg={9} xl={10}>
                            <Grid className='contenedorMedio' container item xs={12} sm lg xl>
                              <Grid container item xs={4} sm={3} lg={2} xl={2} direction='column'>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273' }} variant="caption">
                                    <b className='bAsset'> Placa: </b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273', lineHeight: '1' }} variant="caption" gutterBottom className={classes.text}>
                                    <b className='bAsset'> Serie del bien: </b>
                                  </Typography>
                                </Grid>

                              </Grid>

                              <Grid container item xs={8} sm lg xl direction='column'>
                                <Grid item>
                                  <Typography variant="caption" gutterBottom className="textCards">
                                    {assetFind.placaBien}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className="textCards" variant="caption" gutterBottom>
                                    {assetFind.serieBien}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container item xs={4} sm={3} lg={2} xl={2} direction='column' style={{ justifyContent: 'space-around', flexWrap: 'nowrap' }}>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273' }} variant="caption">
                                    <b className='bAsset'>Estado del bien assetlistsur: </b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273', lineHeight: '1' }} variant="caption" gutterBottom className={classes.text}>
                                    <b className='bAsset'> Responsable: </b>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container item xs={8} sm lg xl direction='column'>
                                <Grid item>
                                  <Typography variant="caption" gutterBottom
                                    style={{
                                      color: props.stages.stages.find(i => i.id == assetFind.estadoBien) ?
                                        props.stages.stages.find(i => i.id == assetFind.estadoBien).color : '',
                                      border: props.stages.stages.find(i => i.id == assetFind.estadoBien) ?
                                        `1px solid ${props.stages.stages.find(i => i.id == assetFind.estadoBien).color}`
                                        : '',
                                      borderRadius: '7px', fontSize: '0.688rem', padding: '0 5px'
                                    }}>
                                    {props.stages.stages.find(i => i.id == assetFind.estadoBien) ?
                                      props.stages.stages.find(i => i.id == assetFind.estadoBien).name
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className="textCards" variant="caption" gutterBottom>
                                    {assetFind.responsable}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container item xs={12} style={{ marginTop: '0' }}>
                              <Grid container item xs={4} sm={3} lg={2} direction='column'>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273' }} variant="caption">
                                    <b className='bAsset'>Dependencia: </b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                                    <b className='bAsset'>Seccional:</b>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container item xs={8} sm={9} lg direction='column'>
                                <Grid item>
                                  <Typography className="textCards" variant="caption">
                                    {assetFind.dependencia}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="caption" className="textCards">
                                    {assetFind.nombreSeccional}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <hr className='hrStyle' />

                            <Grid container item xs={12} style={{ marginBottom: '1rem' }}>
                              <Grid item xs={4} sm={3} lg={2} xl={2}>
                                <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                                  <b className='bAsset'>Ubicación: </b>
                                </Typography>
                              </Grid>
                              <Grid container item xs>
                                <Grid item>
                                  <Typography className="textCards" variant="caption" gutterBottom>
                                    {getnameLocation(assetFind.location_id)}
                                    {headquarterChs != null && headquarterChs.length > 0 ? headquarterChs : null}
                                    {' - '}
                                    {assetFind.location_complement}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Paper>
                  </DialogContent>

                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button className='btnSecundary' onClick={toggleDialog} autoFocus>
                      Cerrar
                    </Button>
                    <Button className='btnPrimary' onClick={() => setSendMailState(true)} autoFocus>
                      Enviar correo
                    </Button>
                  </DialogActions>
                </>
                : 
                <>
                  <DialogContent style={{ padding: '0 3rem' }}>

                    <label className='labelStyle' htmlFor='placa'>Para</label>
                    <TextField style={{ backgroundColor: 'transparent', color: '#5f6273', marginBottom: 7, borderRadius: '1rem' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)} disabled />
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      value={[...ccmail]}
                      freeSolo
                      onChange={(event, input) => {
                        setCcEmail(input)
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant='outlined' style={{ backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <>
                          <label className='labelStyle' htmlFor='CC'>CC</label>
                          <TextField className='inputCC' onBlur={(e) => {
                            if (e.target.value.length > 0) {
                              setCcEmail(ccmail.concat(e.target.value))
                            }
                          }}
                            onKeyDown={e => {
                              if (e.keyCode == 188 && e.target.value) {
                                setCcEmail(ccmail.concat(e.target.value))
                              }
                            }} {...params} type='email' variant="outlined" id='CC' placeholder="Correos" size={'small'} />
                        </>
                      )}
                    />
                    <label style={{ paddingTop: '0.5rem' }} className='labelStyle' htmlFor='asunto'>Asunto</label>
                    <TextField style={{ marginBottom: 7 }} fullWidth id="asunto" value={asuntoMail + ` (${assetFind.placaBien}) - por ${changeType.name}`} size={'small'} variant="outlined" disabled />
                    <Editor
                      apiKey='4kbnibmrsojzegoh7cxijw4b8sjui7oxr9o3qxq3okpmeq5b'
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={templateText()}
                      init={{
                        height: 350,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                    {emailAlert.length > 0 &&
                      <Alert severity={'info'}>{emailAlert.message}</Alert>}
                  </DialogContent>

                  <DialogActions className='actionsFinded'>
                    <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                      Cancelar
                    </Button>
                    <Button disabled={sended} className='btnPrimary' onClick={send} color="primary" autoFocus>
                      {sended ? 'Enviando...' : 'Enviar'}
                    </Button>
                  </DialogActions>
                </>
            }
          </> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Error al enviar correo' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error &&
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        }
      </Dialog>
    );
  };

  useEffect(() => { 
// TLA useEffect que carga listas generales, aqui debe ir mis inscripciones 
// y mis evaluaciones, asi como roles y demas
//    dispatch(fetchTotalMissings(props.user.numero_documento))
    dispatch(fetchInventoryHistory({ idUser: props.user.user_susi_id }))
    dispatch(fetchEvaluations({ idUser: props.user.user_susi_id }))
    dispatch(fetchScore({ idUser: props.user.user_susi_id }))
//    dispatch(fetchRazonReimpresionPlaca()) 
//    dispatch(fetchInventoryOfficers(props.user.numero_documento)) //TLA CAMBIO
    dispatch(fetchDataUser(props.user.numero_documento)) //TLA CAMBIO
  }, [])



  return (
    <nav>
      <div className="card-body-nav">
        <img src={fiscaliapng} className='fiscaliaImagen' alt="" />
        <img src={fiscaliapng2} className='fiscaliaImagen2' alt="" />
        {userrole==0 && (
          openPrint || open ?
          <ListItem className='NavElement' button disableGutters>
            <ListItemIcon className='listItem'>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Inicio" />
          </ListItem> : pathname == '/tonotifys' ?
            <NavLink className='NavElement' to="/" exact>
              <ListItem disableGutters className='NavElement' style={{ justifyContent: 'center' }}>
                <ListItemIcon className='modalActiveIcon'>
                  <HomeIcon style={{ color: '#3f66c4' }} />
                </ListItemIcon>
                <ListItemText className='modalAcitve' primary="Inicio" />
              </ListItem>
            </NavLink>
            :
            <NavLink className='NavElement' to="/" exact>
              <ListItem disableGutters>
                <ListItemIcon className='listItem'>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Inicio" />
              </ListItem>
            </NavLink>
        )}


        {userrole==0 && (
          openPrint || open ?
          <ListItem className='NavElement' button disableGutters>
            <ListItemIcon className='listItem'>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Mis Inscripciones " />
          </ListItem>
          // : props.historyList.history.length > 0 ?
           : <NavLink className='NavElement' to="/toreport">
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Mis Inscripciones " />
              </ListItem>
            </NavLink>
            // :
            // <ListItem className='NavElement' button disableGutters>
            //   <ListItemIcon className='listItem'>
            //     <PlaylistAddCheckIcon />
            //   </ListItemIcon>
            //   <ListItemText className='listItem' primary="Mis Inscripciones" />
            // </ListItem>
        )}

        {userrole==0 && (
          openPrint ?
          <ListItem className='NavElement' onClick={toggleDialogPrint} button disableGutters>
            <ListItemIcon className='modalActiveIcon'>
              <FontAwesomeIcon style={{ color: '#3f66c4' }} icon={faFilePdf} size="2x" />
            </ListItemIcon>
            <ListItemText className='modalAcitve' primary="Descargar Certificación" />
          </ListItem>
          :
          <ListItem className='NavElement' onClick={toggleDialogPrint} button disableGutters>
            <ListItemIcon className='listItem'>
              <FontAwesomeIcon icon={faFilePdf} size="2x" />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Descargar Certificación" />
          </ListItem>
        )}

        {userrole==1 && (openPrint || open ?
          <ListItem className='NavElement' button disableGutters>
            <ListItemIcon className='listItem'>
              <AddAlert />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Mis Evaluaciones" />
          </ListItem>
          : pathname == '/surplusAsset' || pathname == '/registerSurplusAsset' ?
            <NavLink className='NavElement' to="/toleftover" >
              <ListItem button disableGutters>
                <ListItemIcon className='modalActiveIcon'>
                  <AddAlert style={{ color: '#3f66c4' }} />
                </ListItemIcon>
                <ListItemText className='listItem modalAcitve' primary="Mis Evaluaciones" />
              </ListItem>
            </NavLink>
            :
            <NavLink className='NavElement' to="/toleftover" >
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <AddAlert />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Mis Evaluaciones" />
              </ListItem>
            </NavLink>
        )}

        {userrole>=90 && (
          openPrint || open ?
            <ListItem className='NavElement' button disableGutters>
              <ListItemIcon className='listItem'>
                <NotificationsActiveRounded />
              </ListItemIcon>
              <ListItemText className='listItem' primary="Tablero de Control" />
            </ListItem>
            :
            <NavLink className='NavElement' to="/Surpluses" >
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <NotificationsActiveRounded />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Tablero de Control" />
              </ListItem>
            </NavLink>)}

        {/* {userrole==99 && (
        open ?
          <ListItem className='NavElement' onClick={toggleDialog} button disableGutters>
            <ListItemIcon className='modalActiveIcon'>
              <FontAwesomeIcon style={{ color: '#3f66c4' }} icon={faBarcode} size="2x" />
            </ListItemIcon>
            <ListItemText className='modalAcitve' primary="Tablero de Control" /> 
          </ListItem>
          :
          <ListItem className='NavElement' onClick={toggleDialog} button disableGutters>
            <ListItemIcon className='listItem'>
              <FontAwesomeIcon icon={faBarcode} size="2x" />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Tablero de Control" />
          </ListItem>
        )} */}

        {userrole>=90 && (
          openPrint || open ?
          <ListItem className='NavElement' button disableGutters>
            <ListItemIcon className='listItem'>
              <FontAwesomeIcon icon={faPuzzlePiece} style={{ fontSize: '1.3rem' }} />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Administración" />

            <ListItemIcon className='listItem'>
              <FontAwesomeIcon icon={openSubMenu ? faChevronDown : faChevronRight} />
            </ListItemIcon>
            <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className='NavElement' button component={NavLink} to="/crear-evaluadores">
                  <ListItemText primary="Creación de Evaluadores" />
                </ListItem>
                <ListItem className='NavElement' button component={NavLink} to="/asignar-evaluadores">
                  <ListItemText primary="Asignación de Evaluadores" />
                </ListItem>
                <ListItem className='NavElement' button component={NavLink} to="/generar-rubricas">
                  <ListItemText primary="Puntajes Evaluaciones" />
                </ListItem>
              </List>
            </Collapse>

          </ListItem>






          :
          <NavLink className='NavElement' to="/Missings" >
            {/* <ListItem button disableGutters> */}
            <ListItem className='NavElement' button disableGutters onClick={handleClickSubmenu}>              
              <ListItemIcon className='listItem NavElement'>
                <FontAwesomeIcon icon={faPuzzlePiece} style={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText className='listItem' primary="Administración" />
              <ListItemIcon className='listItem'>
                <FontAwesomeIcon icon={openSubMenu ? faChevronDown : faChevronRight} />
              </ListItemIcon>
            </ListItem>
            <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className='NavElement' button component={NavLink} to="/crear-evaluadores">
                  <ListItemText primary="Creación de Evaluadores" />
                </ListItem>
                <ListItem className='NavElement' button component={NavLink} to="/asignar-evaluadores">
                  <ListItemText primary="Asignación de Evaluadores" />
                </ListItem>
                <ListItem className='NavElement' button component={NavLink} to="/generar-rubricas">
                  <ListItemText primary="Calificación Evaluaciones" />
                </ListItem>
              </List>
            </Collapse>


          </NavLink>
        )}

        <hr className='displayNavLg' style={{ backgroundColor: 'rgba(135, 158, 227,0.5)' }} />

        {perfil == 2  && (
          openPrint || open ?
            <ListItem className='NavElement displayNavLg' button disableGutters>
              <ListItemIcon className='listItem'>
                <NotificationsActiveRounded />
              </ListItemIcon>
              <ListItemText className='listItem' primary="Sobrantes Reportados" />
            </ListItem>
            :
            <NavLink className='NavElement displayNavLg' to="/Surpluses" >
              <ListItem button disableGutters>
                <ListItemIcon className='listItem NavElement'>
                  <NotificationsActiveRounded />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Sobrantes Reportados" />
              </ListItem>
            </NavLink>)}

        {perfil == 2 && (
          openPrint || open ?
            <ListItem className='NavElement' button disableGutters>
              <ListItemIcon className='listItem'>
                <FontAwesomeIcon icon={faPuzzlePiece} style={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText className='listItem' primary="Faltantes Reportados" />
            </ListItem>
            :
            <NavLink className='NavElement' to="/ReportMissings" >
              <ListItem button disableGutters>
                <ListItemIcon className='listItem NavElement'>
                  <FontAwesomeIcon icon={faPuzzlePiece} style={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Faltantes Reportados" />
              </ListItem>
            </NavLink>)}

          <ListItem className='NavElement' style={{ position: 'absolute', top: '85%', width: '13rem', justifyContent: 'center' }} onClick={downloadRes} button disableGutters>
            <ListItemText className='listItem' primary="Descargue aquí el Manual del usuario" />
          </ListItem>

        <div className='listItem' style={{ position: 'absolute', top: '95%', width: '13rem', justifyContent: 'center' }}>
          <p>
            Versión {REACT_APP_VERSION}
          </p>
        </div>
      </div>


      {renderDialogMail()}
      {renderDialogPrint()}
    </nav>
  )
};

const mapStateToProps = state => {
  //console.log("estado en lisitems");
  //console.log(state)
  return {
    historyList: state.inventoryHistory,
    evaluations: state.evaluations,
    score      : state.score,
    params: state.getActualParams,
    withoutFillingOuts: state.withoutFillingOut,
    user: state.user,
    assignedAssets: state.assignedAssets,
    locations: state.locations.locations,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    missingReasons: state.SUSILists.missingReasons,
    rePrintReasons: state.SUSILists.reprintReasons,
    inventoryOfficers: state.SUSILists.inventoryOfficers,
    dataUser: state.dataUserReducer.dataDirectory,
    sectionalsUser: state.sectionals,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,
    Whitout: state.missingReducer
  }
}

export default connect(mapStateToProps)(MainListItems2)