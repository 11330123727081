export const REACT_APP_VERSION = '1.0.0';
//export const REACT_APP_BACKEND_URL = 'http://localhost/';
export const REACT_APP_BACKEND_URL = 'https://www.ciicconsultores.com/PremiosHernandoSarasti/apiphp/';
//export const REACT_APP_BACKEND_URL = 'https://www.ciicconsultoresqa.com/PremiosHernandoSarasti/apiphp/';
export const RESOLUCION_INVENTARIO = '/assets/docs/MANUAL_USUARIO.pdf';
export const publicUrl = process.env.PUBLIC_URL;
export const DOCUMENTS_FOLDER = '/assets/docs/';
export const susi_lists = {
    replacements_states: {
        without_management_id: 173521,
        aproved_id: 173523,
        rejected_id: 173524,
        based_id: 173522,
    },
    newness: {
        missing: 160693,
        checked: 160692
    }
}
export const file_size_MB = 1024