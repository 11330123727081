import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from 'xlsx';
import React, { useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio,  Checkbox, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Badge from "@material-ui/core/Badge";
import { Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';

import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import "../../assets/css/online-inventory.css";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";

import { createAssignEvaluation, updateState } from "../../redux/actions/inscriptionAction";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { fetchCompany } from "../../redux/actions/SUSIListsActions";
import { fetchEvaluationType } from "../../redux/actions/SUSIListsActions";
import { fetchSpeciality } from "../../redux/actions/SUSIListsActions";
import { fetchThematicArea } from "../../redux/actions/SUSIListsActions";
import { fetchStepState } from "../../redux/actions/SUSIListsActions";
import { fetchEvaluations } from '../../redux/actions/evaluationsActions';
import { Label } from "@material-ui/icons";
import Register from '../Main/Register';

import {Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';



import TableSortLabel from '@material-ui/core/TableSortLabel';
import {DOCUMENTS_FOLDER} from "../../config";
import { fetchEvaluators } from "../../redux/actions/evaluatorActions";
import { useEffect } from "react";
import { Tooltip } from '@material-ui/core';
import { isEmpty } from "lodash";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
    color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const generatePassword = () => {
  return Math.random().toString(36).slice(-8); // Genera una contraseña aleatoria de 8 caracteres
};

class RubricGenerate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        idcard: 0,
        name: '',
        lastname: '',
        evaluationType: [],
        companyId: 0,
        companyName: '',
        categoryId : 0,
        categoryName: '',
        specialityId: 0,
        specialityName: '',
        username: '',
        password: generatePassword(),
        passwordConfirm: '',
        rol: 0,
        rolName: '',
        evaluatorFirstId: 0,
        evaluatorFirstFullName: '',
        evaluatorSecondId: 0,
        evaluatorSecondFullName: '',
        evaluatorThirdId: 0,
        evaluatorThirdFullName: '',
      },
      errors: {},
      messageRegister:  "",
      openCreateEvaluator:  false,
      isValid:              false,
      openDialogSort:       false,
      openDialogFilter:     false,
      valueSort:         'ASC',
      valueFilter:       '',
      optionSelected:        null,
      filterApplied:         false,
      orderBy:           'name',
      order:             'asc',
      filterText: '',
      filteredData: [],
      checkedEvaluator1: {},
      checkedEvaluator2: {},
      checkedEvaluator3: {},
      page: 0,
      rowsPerPage: 6,
      userrole: window.sessionStorage.getItem('userrole'),
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      openView: false,
      showDialogStatusBad: false,
      messageAlert: '',
      titleAlert: '',
      pdfBlobUrl: '',
      roles : [
        { rolId: 1, perfil: 'Evaluador' },
        { rolId: 98, perfil: 'Administrador evaluadores entidad' },
        { rolId: 99, perfil: 'Administrador general' }
      ]
    };

  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }), () => {
      if (name === 'evaluationTypeId') {
        this.filterEvaluators();
      }
      if (name === 'areaId') {
        this.filterEvaluations();
      }
      if (name === 'categoryId') {
        this.filterEvaluations();
      }
      if (name === 'evaluatorFirstId') {
        this.filterEvaluations();
      }
      if (name === 'evaluatorSecondId') {
        this.filterEvaluations();
      }
      if (name === 'evaluatorThirdId') {
        this.filterEvaluations();
      }            
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  componentDidMount() {
    this.getInformation();
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      company: window.sessionStorage.getItem('userCompany'),
      scopeRole : window.sessionStorage.getItem('scopeRole'),
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchEvaluators(params));
    this.props.dispatch(fetchEvaluations());
    this.props.dispatch(fetchCompany());
    this.props.dispatch(fetchEvaluationType());
    this.props.dispatch(fetchSpeciality());
    this.props.dispatch(fetchThematicArea());
    this.props.dispatch(fetchStepState());
    this.props.dispatch(fetchInventoryHistory());
  }


  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }


  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
    console.log(this.props.evaluationsList);
    const evaluations  = this.props.evaluationsList;
    console.log(evaluations);
    if (!Array.isArray(evaluations)) {
      console.log("retorna");
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = evaluations.filter((item) => {
        return (
          (item.areaDescription?.toLowerCase() ?? '').includes(filterText) ||
          (item.categoryName?.toLowerCase() ?? '').includes(filterText) ||
          (item.articleShortTitle?.toLowerCase() ?? '').includes(filterText) ||
          (item.articleTitle?.toLowerCase() ?? '').includes(filterText) ||
          (item.state?.toString() ?? '').includes(filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };


handleState = (inscriptionId) => {
  // Definir un array de evaluadores y los correspondientes estados a actualizar
  const evaluators = [
    { id: this.state.formData.evaluatorFirstId, stateKey: 'state1', evaluatorKey: 'evaluatorId1' },
    { id: this.state.formData.evaluatorSecondId, stateKey: 'state2', evaluatorKey: 'evaluatorId2' },
    { id: this.state.formData.evaluatorThirdId, stateKey: 'state3', evaluatorKey: 'evaluatorId3' }
  ];

  // Mantener una copia del estado actual de filteredEvaluations
  let updatedFilteredEvaluations = [...this.state.filteredEvaluations];

  // Iterar sobre cada evaluador para actualizar el estado si el evaluador está asignado
  evaluators.forEach(({ id, stateKey, evaluatorKey }) => {
    if (id > 0) {
      updateState(inscriptionId, id, 3)
        .then(dataAssign => {
          if (dataAssign) {
            console.log(`Actualización de ${stateKey} realizada con éxito`);
            console.log(dataAssign);

            // Actualizar la evaluación específica en filteredEvaluations
            updatedFilteredEvaluations = updatedFilteredEvaluations.map(evaluation => {
              if (evaluation.inscriptionId === inscriptionId) {
                const newState = this.props.stepStateList.find(state => state.stateId === 3);
                const newStateName = newState ? newState.state : 'Estado no encontrado';
                
                return {
                  ...evaluation,
                  [evaluatorKey]: id,
                  [`${stateKey}Id`]: 3,
                  [stateKey]: newStateName,
                };
              }
              return evaluation;
            });

            // Actualizar el estado del componente
            this.setState({ filteredEvaluations: updatedFilteredEvaluations });
          }
        })
        .catch(error => {
          console.error(`Error en la actualización de ${stateKey}:`, error);
        });
    }
  });
};


  downloadFile = async (inscriptionId) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER + currentYear + '/ArticlePdf_' + inscriptionId + '.pdf';
    console.log("path en historico");
    console.log(process.env.PUBLIC_URL);
    console.log(pdfPath);
    // Descargar el archivo PDF como un Blob
    const response = await fetch(pdfPath);
    const blob = await response.blob();

    // Crear una URL para el Blob
    const blobUrl = URL.createObjectURL(blob);

    // Actualizar el estado con la URL del Blob
    this.setState({ pdfBlobUrl: blobUrl });
  }


  handleOpenView = (inscriptionId) => {
    this.downloadFile(inscriptionId);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  handleshowDialogStatusBad = () => {
    this.setState({
      showDialogStatusBad: true
    });
  };

  handleCloseDialogStatusBad = () => {
    this.setState({
      showDialogStatusBad: false
    });
  };

  filterEvaluators = () => {
    const {formData } = this.state;
    const { evaluationTypeId } = formData;
    console.log(evaluationTypeId);
    console.log(this.props.evaluatorsList);
    if (!this.props.evaluatorsList || !evaluationTypeId) {
      this.setState({ filteredEvaluators: [] }, () => {
        console.log(this.state.filteredEvaluators);
      });
      return;
    }

    let filteredEvaluators = this.props.evaluatorsList.filter(evaluator => 
      evaluator.evaluationType.includes(Number(evaluationTypeId))
    );
    this.setState({ filteredEvaluators }, () => {
      console.log(this.state.filteredEvaluators);
    });
  }

  validateEvaluators = () => {
    const { evaluatorFirstId, evaluatorSecondId, evaluatorThirdId } = this.state.formData;
    let errors = {};
    let titleAlert = 'VALIDACIÓN EVALUADORES';
    if (evaluatorFirstId && evaluatorFirstId != 0 && (evaluatorFirstId == evaluatorSecondId || evaluatorFirstId == evaluatorThirdId)) {
      errors.evaluator = "Los evaluadores no pueden ser iguales.";
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          evaluatorSecondId: 0
        }
      }));
    }
  
    if (evaluatorSecondId && evaluatorSecondId != 0  && (evaluatorSecondId === evaluatorFirstId || evaluatorSecondId === evaluatorThirdId)) {
      errors.evaluator = "Los evaluadores no pueden ser iguales.";
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          evaluatorSecondId: 0
        }
      }));
    }
  
    if (evaluatorThirdId && evaluatorThirdId != 0 && (evaluatorFirstId > 0 || evaluatorSecondId > 0)) {
      errors.evaluator = "No debe seleccionar sino el tercer evaluador";
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          evaluatorSecondId: 0,
          evaluatorFirstId: 0
        }
      }));
    }
  
    this.setState({titleAlert, messageAlert:errors.evaluator});
    this.setState({ errors });
    
    // Devuelve verdadero si no hay errores, falso en caso contrario
    return Object.keys(errors).length == 0;
  }

  filterEvaluations = () => {
    console.log(this.state);
    console.log(this.props);
    const { formData } = this.state;
    const { areaId, categoryId } = formData;
  
    console.log(this.props.evaluationsList);
  
    if (!this.props.evaluationsList) {
      this.setState({ filteredEvaluations: [] }, () => {
        console.log(this.state.filteredEvaluations);
      });
      return;
    }
  
    let filteredEvaluations = [...this.props.evaluationsList]; // Crear una copia del array
  
    // Filtrar por areaId
    if (areaId != 0) {
      console.log("areaId", areaId);
      filteredEvaluations = filteredEvaluations.filter(evaluation => evaluation.thematicAreaId === Number(areaId));
      console.log(filteredEvaluations);
    }
  
    // Filtrar por categoryId
    if (categoryId != 0) {
      console.log("categoryId", categoryId);
      console.log(filteredEvaluations);
      filteredEvaluations = filteredEvaluations.filter(evaluation => evaluation.categoryID === Number(categoryId));
      console.log(filteredEvaluations);
    }
  
    // Validar evaluadores
    if (this.validateEvaluators()) {
      const { evaluatorFirstId, evaluatorSecondId, evaluatorThirdId } = formData;
      // Filtrar por evaluatorFirstId si está seleccionado
      if (evaluatorFirstId && evaluatorFirstId != 0) {
          filteredEvaluations = filteredEvaluations.filter(evaluation => {
          const comparisonResult = Number(evaluation.evaluatorId1) === Number(evaluatorFirstId);
          const isEmptyResult = evaluation.evaluatorId1 == null || evaluation.evaluatorId1 === 0;
          return comparisonResult || isEmptyResult;
      });        
      }

      // Filtrar por evaluatorSecondId si está seleccionado

      if (evaluatorSecondId && evaluatorSecondId != 0) {
        filteredEvaluations = filteredEvaluations.filter(evaluation => {
        const comparisonResult  = Number(evaluation.evaluatorId2) === Number(evaluatorSecondId);
        const comparisonResult1 = Number(evaluation.evaluatorId1) > 0 && Number(evaluation.evaluatorId1) !=  Number(evaluatorSecondId);
        const isEmptyResult     = evaluation.evaluatorId2 == null || evaluation.evaluatorId2 === 0;
        return (comparisonResult || isEmptyResult) && comparisonResult1;
      });        
      }

      // Filtrar por evaluatorThirdId si está seleccionado
      if (evaluatorThirdId && evaluatorThirdId != 0) {
        filteredEvaluations = filteredEvaluations.filter(evaluation => {
        const comparisonResult  = Number(evaluation.evaluatorId3) === Number(evaluatorThirdId);
        const comparisonResult1 = Number(evaluation.evaluatorId1) > 0 && Number(evaluation.evaluatorId1) !=  Number(evaluatorThirdId);
        const comparisonResult2 = Number(evaluation.evaluatorId2) > 0 && Number(evaluation.evaluatorId2) !=  Number(evaluatorThirdId);
        const comparisonResult3 = Number(evaluation.score1) > 0 && Number(evaluation.score2) > 0 && Number(evaluation.score1) === Number(evaluation.score2);
        const isEmptyResult     = evaluation.evaluatorId3 == null || evaluation.evaluatorId3 === 0;
        return (comparisonResult || isEmptyResult) && comparisonResult1 && comparisonResult2 && comparisonResult3;
      });        
      }
    } else {
      this.handleshowDialogStatusBad();
    }
  
    this.setState({ filteredEvaluations }, () => {
      console.log(this.state.filteredEvaluations);
    });
  }
  

  handleEvaluator1Change = (inscriptionId) => {
    const { filteredEvaluations, formData } = this.state;
  
    // Actualiza el array filteredEvaluations
    let updatedFilteredEvaluations = filteredEvaluations.map(evaluation => {
      if (evaluation.inscriptionId === inscriptionId) {
        const isChecked = evaluation.evaluatorId1 > 0;
        const newEvaluatorId1 = isChecked ? 0 : formData.evaluatorFirstId; // Alternar entre asignado (formData.evaluatorFirstId) y no asignado (0)
        const newStateId1 = isChecked ? 1 : 2; // Alternar el estado basado en si se asignó un evaluador
        const newState1 = this.props.stepStateList.find(state => state.stateId === newStateId1);
        const newStateName = newState1 ? newState1.state : 'Estado no encontrado';
  
        return {
          ...evaluation,
          evaluatorId1: newEvaluatorId1,
          stateId1    : newStateId1,
          state1      : newStateName,
        };
      }
      return evaluation;
    });
  
    this.setState({
      filteredEvaluations: updatedFilteredEvaluations
    });
  };
  
 

  handleEvaluator2Change = (inscriptionId) => {
    const { filteredEvaluations, formData } = this.state;
  
    // Actualiza el array filteredEvaluations
    let updatedFilteredEvaluations = filteredEvaluations.map(evaluation => {
      if (evaluation.inscriptionId === inscriptionId) {
        const isChecked = evaluation.evaluatorId2 > 0;
        const newEvaluatorId2 = isChecked ? 0 : formData.evaluatorFirstId; // Alternar entre asignado (formData.evaluatorFirstId) y no asignado (0)
        const newStateId2 = isChecked ? 1 : 2; // Alternar el estado basado en si se asignó un evaluador
        const newState2 = this.props.stepStateList.find(state => state.stateId === newStateId2);
        const newStateName = newState2 ? newState2.state : 'Estado no encontrado';
  
        return {
          ...evaluation,
          evaluatorId2: newEvaluatorId2,
          stateId2    : newStateId2,
          state2      : newStateName,
        };
      }
      return evaluation;
    });
  
    this.setState({
      filteredEvaluations: updatedFilteredEvaluations
    });
  };

  handleEvaluator3Change = (inscriptionId) => {
    const { filteredEvaluations, formData } = this.state;
  
    // Actualiza el array filteredEvaluations
    let updatedFilteredEvaluations = filteredEvaluations.map(evaluation => {
      if (evaluation.inscriptionId === inscriptionId) {
        const isChecked = evaluation.evaluatorId3 > 0;
        const newEvaluatorId3 = isChecked ? 0 : formData.evaluatorFirstId; // Alternar entre asignado (formData.evaluatorFirstId) y no asignado (0)
        const newStateId3 = isChecked ? 1 : 2; // Alternar el estado basado en si se asignó un evaluador
        const newState3 = this.props.stepStateList.find(state => state.stateId === newStateId3);
        const newStateName = newState3 ? newState3.state : 'Estado no encontrado';
  
        return {
          ...evaluation,
          evaluatorId3: newEvaluatorId3,
          stateId3    : newStateId3,
          state3      : newStateName,
        };
      }
      return evaluation;
    });
  
    this.setState({
      filteredEvaluations: updatedFilteredEvaluations
    });
  };

  handleSave = () => {
    // Lógica para guardar la página o los datos
    console.log("Descargando los datos...");
      const data = [
        { competitionId: 1, presentationId: 1, articleTitle: "TITULO 1" },
        { competitionId: 2, presentationId: 2, articleTitle: "TITULO 2" },
        { competitionId: 3, presentationId: 3, articleTitle: "TITULO 3" }
      ];
    const transformData = (data) => {
      return data.map(entry => ({
        // "Entidad": "Fundación Cardio Infantil",
        // "Número Inscripción": entry.inscriptionId,
        // "Tipo Evaluación": "Metodológica",
        // "Competition ID": entry.competitionId,
        // "Presentation ID": entry.presentationId,
        // "Category ID": entry.categoryID,
        "Categoría": entry.categoryName,
        // "Thematic Area ID": entry.thematicAreaId,
        "Area Temática": entry.areaDescription,
        // "Member ID": entry.memberIdcard,
        "Título Corto": entry.articleShort,
        "Título": entry.articleTitle,
        // "Keyword 1": entry.articleKeyword1,
        // "Keyword 2": entry.articleKeyword2,
        // "Keyword 3": entry.articleKeyword3,
        // "Objective": entry.articleObjetive,
        // "Method": entry.articleMethod,
        // "Result": entry.articleResult,
        // "Conclusion": entry.articleConclusion,
        // "File 1": entry.articleFile1,
        // "File 2": entry.articleFile2,
        // "File 3": entry.articleFile3,
        // "Inscription Date": entry.inscriptionDate,
        // "State 1": entry.state1,
        "Puntaje Primer Evaluador": entry.score1,
        // "Evaluator ID 1": entry.evaluatorId1,
        // "State 2": entry.state2,
        "Puntaje Segundo Evaluador": entry.score2,
        "Puntaje Promedio": (entry.score1 + entry.score2) / 2,
        // "Evaluator ID 2": entry.evaluatorId2,
        // "State 3": entry.state3,
        "Puntaje Tercer Evaluador": entry.score3
        // "Evaluator ID 3": entry.evaluatorId3
      }));
    };



    const transformedData = transformData(this.dataToRender);
    console.log("Datos transformados:", transformedData);

    if (transformedData.length > 0) {
      // Crear hoja de trabajo (worksheet) a partir de los datos transformados
      const worksheet = XLSX.utils.json_to_sheet(transformedData);
    
      // Crear libro de trabajo (workbook) y agregar la hoja de trabajo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
      // Guardar el archivo Excel
      XLSX.writeFile(workbook, "single_field_export.xlsx");
    } else {
      console.error("Los datos transformados están vacíos. No se creará el archivo.");
    }

  };


  render() {

    const getRowStyle = (state) => {

      switch (state) {
        case 1:
          return { backgroundColor: '#ffcccc' }; // Rojo claro
        case 2:
          return { backgroundColor: '#ffebcc' }; // Naranja claro
        case 3:
          return { backgroundColor: '#ffffcc' }; // Amarillo claro
        case 4:
          return { backgroundColor: '#ccffcc' }; // Verde claro
        case 5:
          return { backgroundColor: '#cce5ff' }; // Azul claro
        default:
          return {};
      }
    }

    const { classes } = this.props;
    const { formData, errors } = this.state;
    const { companyList, evaluationTypeList, evaluationsList=[],
            categoryList, thematicAreaList } = this.props;
    const { areaId, categoryId } = formData;

    //console.log("props", this.props);
    //console.log("state", this.state);
    
    // console.log("specialityList:", specialityList);
    // console.log("evaluationTypeList:", evaluationTypeList);
    // console.log("categoryList", categoryList);
    // console.log("evaluatorsList", evaluatorsList);
    // console.log("evaluationsList")
    
    const { order, orderBy, page, rowsPerPage, filterText, filteredData=[] } = this.state;
    // const dataToRender = (categoryId == 0 && areaId == 0 && !filterText) ? evaluationsList : filteredEvaluations;
    console.log(evaluationsList);
    console.log("filtrado");
    console.log(filteredData);
    const dataToRender = this.stableSort(filteredData && filteredData.length>0 && filterText != "" ? filteredData : evaluationsList, this.getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const dataLength = filteredData && filteredData.length>0  ? filteredData.length : evaluationsList.length;



    console.log(dataToRender);


    return (
      <div style={{ overflowX: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>DETALLE DE EVALUACIONES</h5>
      

      <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {dataToRender.length > 0 ? (
            <Table responsive={true}>
            <TableHead>
              <TableRow>
              <TableCell>
                  <TableSortLabel
                    active={orderBy === 'areaDescription'}
                    direction={orderBy === 'areaDescription' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('areaDescription')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Área Temática
                  </TableSortLabel>
                </TableCell>  
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'categoryName'}
                    direction={orderBy === 'categoryName' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('categoryName')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Categoría
                  </TableSortLabel>
                </TableCell>     
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionId'}
                    direction={orderBy === 'inscriptionId' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionId')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Inscripción
                  </TableSortLabel>
                </TableCell>                
                {/* <TableCell>
                  <TableSortLabel
                    active={orderBy === 'state1'}
                    direction={orderBy === 'state1' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('state1')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Estado Evaluación
                  </TableSortLabel>
                </TableCell> */}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleShortTitle'}
                    direction={orderBy === 'articleShortTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleShortTitle')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Título Corto
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleTitle'}
                    direction={orderBy === 'articleTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleTitle')}
                    style={{ color: 'var(--backBlue)' }}
                  >
                    Título del Trabajo
                  </TableSortLabel>
                </TableCell>

                <TableCell style={{ color: 'var(--backBlue)' }}>
                    Evaluador 1
                </TableCell>

                <TableCell style={{ color: 'var(--backBlue)' }}>
                    Evaluador 2
                </TableCell>

                <TableCell style={{ color: 'var(--backBlue)' }}>
                    Promedio
                </TableCell>

                <TableCell style={{ color: 'var(--backBlue)' }}>
                    Evaluador 3
                </TableCell>

                {/* <TableCell style={{ color: 'var(--backBlue)' }}>
                    Enviar
                </TableCell> */}
                {/* <TableCell style={{ color: 'var(--backBlue)' }}>
                    Limpiar
                </TableCell>                 */}
              </TableRow>
            </TableHead>
            <TableBody>
            {dataToRender.length > 0 && this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow sx={getRowStyle(row.stateId1)} key={row.inscriptionId}>
                <TableCell>{row.areaDescription}</TableCell>
                <TableCell>{row.categoryName}</TableCell>
                <TableCell>{row.inscriptionId}</TableCell>
                {/* <TableCell>1) {row.state1}, 2) {row.state2}</TableCell> */}
                <TableCell>{row.articleShortTitle}</TableCell>
                <TableCell>{row.articleTitle}</TableCell>
                <TableCell>{row.score1}</TableCell>
                <TableCell>{row.score2}</TableCell>
                <TableCell>{(row.score1+row.score2)/2}</TableCell>
                <TableCell>{row.score3}</TableCell>
                                 
                {/* <TableCell align="center">
                  <IconButton aria-label="Solicitar Aceptación" size="small" 
                  onClick={() => this.handleState(row.inscriptionId)}
                  disabled={(this.state.formData.evaluatorFirstId>0  && row.stateId1 != 2)  ||
                            (this.state.formData.evaluatorSecondId>0 && row.stateId2 != 2) ||
                            (this.state.formData.evaluatorThirdId>0  && row.stateId3 != 2)
                  }>
                    <EmailIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell> */}
                {/* <TableCell align="center">
                  <IconButton aria-label="editar" size="small" onClick={() => this.handleOpenCreateEvaluator(row.idUser)}>
                    <DeleteIcon fontSize="large" style={{ color: 'red' }}/>
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron inscripciones para asignar.
            </Alert>
          )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página" 
        />

      <div style={{ textAlign: 'right', padding: '16px' }}>
        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={this.handleSave}
          style={{ marginRight: '8px' }}
        >
          Descargar
        </Button> */}
        <IconButton aria-label="Descargar" size="small" onClick={this.handleSave}>
          <GetAppIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
          <Typography variant="body2" style={{ marginLeft: 8 }}>Descargar</Typography>
        </IconButton>



        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          onClick={this.handleSave}
        >
          Enviar
        </Button> */}
      </div>


    <Dialog className='dialogReounded estadoBien'
      open={this.state.showDialogStatusBad}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ padding: '1rem', borderRadius: '20px!important', boxShadow: '0!important' }}
    >
      <DialogTitle id="form-dialog-title" color='red'>{this.state.titleAlert}</DialogTitle>
      <DialogContent style={{ padding: '40px 39px 15px' }}>
        <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
        {this.state.messageAlert}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center', marginBottom: '0.938rem' }}>
        <Button className='btnError' variant='outlined' onClick={this.handleCloseDialogStatusBad}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>


{/* DIALOGO DE VISUALIZACIÓN */}
<Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              // overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >

            <DialogTitle className='dialogTttle' id="alert-dialog-title">TRABAJO INSCRITO</DialogTitle>
            <DialogContent style={{ paddingLeft: '2rem' }}>
              <DialogContentText id="alert-dialog-description">
               {this.state.pdfBlobUrl && <iframe src={this.state.pdfBlobUrl} width="100%" height="600px"></iframe>}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluatorsList    : state.evaluators.evaluators,
  evaluationsList   : state.evaluations.evaluations,
  companyList       : state.SUSILists.company,
  categoryList      : state.SUSILists.categories,
  evaluationTypeList: state.SUSILists.evaluationType,
  specialityList    : state.SUSILists.speciality,
  thematicAreaList  : state.SUSILists.thematicArea,
  stepStateList     : state.SUSILists.stepState,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(RubricGenerate);