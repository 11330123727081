import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio,  Checkbox, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Badge from "@material-ui/core/Badge";
import { Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { createEvaluator, getEvaluator } from "../../redux/actions/inscriptionAction";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { fetchCompany } from "../../redux/actions/SUSIListsActions";
import { fetchEvaluationType } from "../../redux/actions/SUSIListsActions";
import { fetchSpeciality } from "../../redux/actions/SUSIListsActions";
import { Label } from "@material-ui/icons";
import Register from '../Main/Register';

import {Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';



import TableSortLabel from '@material-ui/core/TableSortLabel';
import {DOCUMENTS_FOLDER} from "../../config";
import { fetchEvaluators } from "../../redux/actions/evaluatorActions";
import { useEffect } from "react";
import { Tooltip } from '@material-ui/core';

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
    color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const generatePassword = () => {
  return Math.random().toString(36).slice(-8); // Genera una contraseña aleatoria de 8 caracteres
};



class Evaluator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        idcard: 0,
        name: '',
        lastname: '',
        evaluationType: [],
        companyId: 0,
        companyName: '',
        specialityId: 0,
        specialityName: '',
        username: '',
        password: generatePassword(),
        passwordConfirm: '',
        rol: 0,
        rolName: '',
      },
      errors: {},
      messageRegister:  "",
      openCreateEvaluator:  false,
      isValid:              false,
      openDialogSort:       false,
      openDialogFilter:     false,
      valueSort:         'ASC',
      valueFilter:       '',
      optionSelected:        null,
      filterApplied:         false,
      orderBy:           'name',
      order:             'asc',
      filterText: '',
      filteredData: [],
      userrole: window.sessionStorage.getItem('userrole'),
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      openView: false,
      pdfBlobUrl: '',
      roles : [
        { rolId: 1, perfil: 'Evaluador' },
        { rolId: 98, perfil: 'Administrador evaluadores entidad' },
        { rolId: 99, perfil: 'Administrador general' }
      ]
    };

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }),
  ()=>{
    console.log(this.state.formData);
  });
  };

  validate = () => {
    const { formData } = this.state;
    let formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'specialityName' && key !== 'passwordConfirm') {
        formErrors[key] = 'Este campo es requerido';
      }
    });
    if (formData.username && !/\S+@\S+\.\S+/.test(formData.username)) {
      formErrors.username = 'Debe ser un correo electrónico válido';
    }
    if (formData.password !== formData.passwordConfirm) {
      formErrors.passwordConfirm = 'Las contraseñas no coinciden';
    }
    this.setState({ errors: formErrors });
    return Object.keys(formErrors).length === 0;
  };




  componentDidMount() {
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    // this.props.dispatch(fetchAssetsStatus());
    // this.props.dispatch(fetchChangesTypes());
    // this.props.dispatch(fetchAssetStages());
    // this.props.dispatch(fetchMissingReasons());
    // this.props.dispatch(fetchLocations());
    // this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      company: window.sessionStorage.getItem('userCompany'),
      scopeRole: window.sessionStorage.getItem('scopeRole'),
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchEvaluators(params));
    this.props.dispatch(fetchCompany());
    this.props.dispatch(fetchEvaluationType());
    this.props.dispatch(fetchSpeciality());
  }


  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }

  handleSubmit = () => {
    console.log("al grabar");
    console.log(this.state);
    console.log(this.state.userrole);
    console.log(window.sessionStorage.getItem('userCompany'));
    
    if (Number(this.state.userrole) === 98) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          companyId: window.sessionStorage.getItem('userCompany'),
          rol: 1,
        }
      }), () => {
        // Esta función de callback se ejecuta después de que el estado se haya actualizado
        console.log("grabando en codigo 98");
        console.log(this.state.formData);
        createEvaluator(this.state.formData)
          .then(dataEvaluator => {
            if (dataEvaluator) {
              const params = {
                company: window.sessionStorage.getItem('userCompany'),
                scopeRole: window.sessionStorage.getItem('scopeRole'),
                order: this.state.valueSort,
                vigencia: this.state.valueFilter
              };
              this.props.dispatch(fetchEvaluators(params));
              console.log("registro actualizado");
              console.log(dataEvaluator);
            }
          })
          .catch(error => {
            console.error("Error en la creación del evaluador:", error);
          });
      });
    } else {
      // Maneja el caso cuando userrole no es 98
      // Por ejemplo, podrías llamar a createEvaluator con el formData original
      createEvaluator(this.state.formData)
        .then(dataEvaluator => {
          if (dataEvaluator) {
            const params = {
                company: this.state.formData.companyId ,
                scopeRole: window.sessionStorage.getItem('scopeRole'),
                order: this.state.valueSort,
                vigencia: this.state.valueFilter
            };
            this.props.dispatch(fetchEvaluators(params));
            console.log("registro actualizado");
            console.log(dataEvaluator);
          }
        })
        .catch(error => {
          console.error("Error en la creación del evaluador:", error);
        });
    }
    this.handleCloseCreateEvaluator();
  };
  

  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  handleCheckboxChange = (typeId) => {
    this.setState(prevState => {
      const newEvaluationType = prevState.formData.evaluationType.includes(typeId)
        ? prevState.formData.evaluationType.filter(id => id !== typeId)
        : [...prevState.formData.evaluationType, typeId];

      return {
        formData: {
          ...prevState.formData,
          evaluationType: newEvaluationType,
        },
      };
    });
  };

  handleRole = (event) => {
    console.log(this.state);
    console.log(event.target.value);
    const selectedRole = this.state.roles.find(rol => rol.rolId === parseInt(event.target.value));
    console.log(selectedRole);
    this.setState(prevState => ({
        formData: {
            ...prevState.formData,
            rol: event.target.value,
            rolName: selectedRole ? selectedRole.perfil : ''
        }
    }));
};

  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { evaluators } = this.props.evaluatorsList;
  
    if (!Array.isArray(evaluators)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = evaluators.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.state.filterText) ||
          item.lastname.toLowerCase().includes(this.state.filterText) ||
          item.evaluationType.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (inscriptionId) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER + currentYear + '/ArticlePdf_' + inscriptionId + '.pdf';
    console.log("path en historico");
    console.log(process.env.PUBLIC_URL);
    console.log(pdfPath);
    // Descargar el archivo PDF como un Blob
    const response = await fetch(pdfPath);
    const blob = await response.blob();

    // Crear una URL para el Blob
    const blobUrl = URL.createObjectURL(blob);

    // Actualizar el estado con la URL del Blob
    this.setState({ pdfBlobUrl: blobUrl });
  }


  handleOpenView = (inscriptionId) => {
    this.downloadFile(inscriptionId);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  handleOpenCreateEvaluator = async (idUser) => {
    if(idUser > 0) {
        // const evaluatorModify = await 
        const evaluatorModify = await getEvaluator(idUser);
        this.setState({
            formData: {
                idcard: evaluatorModify.idcard,
                name: evaluatorModify.name,
                lastname: evaluatorModify.lastname,
                evaluationType: evaluatorModify.evaluationType,
                companyId: evaluatorModify.companyId,
                companyName: evaluatorModify.companyName,
                specialityId: evaluatorModify.specialityId,
                specialityName: evaluatorModify.specialityName,
                username: evaluatorModify.email,
                password: evaluatorModify.password,
                passwordConfirm: evaluatorModify.passwordConfirm,
                rol: evaluatorModify.role,
                rolName: evaluatorModify.rolName,
                idUser : idUser,
            },
            openCreateEvaluator: true
        });
    } else {
        this.setState({
            openCreateEvaluator: true
        });
    }
}

  handleCloseCreateEvaluator = () => {
    this.setState({
      openCreateEvaluator: false
    });
  };

  render() {

    const { classes } = this.props;
    const { formData, errors } = this.state;
    const { evaluatorsList, companyList, evaluationTypeList, specialityList } = this.props;
    console.log("evaluatorsList:", evaluatorsList);
    console.log("specialityList:", specialityList);
    const { order, orderBy, filterText, filteredData } = this.state;
    const dataToRender = filterText ? filteredData : evaluatorsList.evaluators;
    console.log(dataToRender);


    return (
      <div style={{ overflowX: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>EVALUADORES REGISTRADOS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />
      <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start' alignItems='center'>
                <Fab color="success" size="small" aria-label="add" onClick={() => this.handleOpenCreateEvaluator(0)}> 
                <AddIcon color="rgb(0, 166, 206)" />
                </Fab>
                <label className='labelBold' >Evaluadores:</label>
            </Grid>
      </Grid>     

      {dataToRender.length > 0 ? (
            <Table responsive={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'evaluationType'}
                    direction={orderBy === 'evaluationType' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('evaluationType')}
                  >
                    Tipo de Evaluador
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('name')}
                  >
                    Nombres
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'lastname'}
                    direction={orderBy === 'lastname' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('lastname')}
                  >
                    Apellidos
                  </TableSortLabel>
                </TableCell>
                <TableCell>Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {dataToRender.length > 0 && this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.idUser}>
                <TableCell>{row.evaluationType}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.lastname}</TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenView(row.inscriptionId)}>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell> */}
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell> */}
                <TableCell align="center">
                  <IconButton aria-label="editar" size="small" onClick={() => this.handleOpenCreateEvaluator(row.idUser)}>
                    <EditIcon fontSize="large" />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssignmentTurnedInIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssessmentIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => this.handlePrint(row.inscriptionId)}>
                    Imprimir
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Evaluadores.
            </Alert>
          )}

{/* DIALOGO CREACION DE EVALUADOR*/}
<Dialog
      PaperProps={{
        style: {
          paddingBottom: '0 !important',
          maxHeight: '95vh !important',
          width: '80vw',
          maxWidth: 'none !important',
          opacity: '1 !important',
        }
      }}
      fullWidth={false}
      maxWidth={false}
      className='dialogReounded dialogUbication'
      open={this.state.openCreateEvaluator}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className='dialogTttle' id="alert-dialog-title">DATOS DEL EVALUADOR</DialogTitle>
      <DialogContent style={{ paddingLeft: '2rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Cédula
            </Typography>
            <TextField
              fullWidth
              name="idcard"
              value={formData.idcard}
              onChange={this.handleChange}
              error={!!errors.idcard}
              helperText={errors.idcard}
              size="small"  // Tamaño pequeño
              variant="standard"
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px',  // Ajusta el relleno interno del TextField
                  '& fieldset': {
                    borderColor: 'rgba(0, 255, 0, 0.5)',  // Cambia el color del borde
                    borderWidth: '1px',  // Ancho del borde
                    borderRadius: '4px',  // Radio de borde
                  },
                },
                '& .MuiOutlinedInput-input': {
                  py: '0px',  // Ajusta el padding vertical del texto dentro del TextField
                },
                marginBottom: '10px',
              }}
            />
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Nombres
            </Typography>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={this.handleChange}
              error={!!errors.name}
              helperText={errors.name}
              size="small"  // Tamaño pequeño
              variant="standard"
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px',  // Ajusta el relleno interno del TextField
                  '& fieldset': {
                    borderColor: 'rgba(0, 255, 0, 0.5)',  // Cambia el color del borde
                    borderWidth: '1px',  // Ancho del borde
                    borderRadius: '4px',  // Radio de borde
                  },
                },

                marginBottom: '10px',
              }}
              
            />

            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Apellidos
            </Typography>
            <TextField
              fullWidth
              name="lastname"
              value={formData.lastname}
              onChange={this.handleChange}
              error={!!errors.lastname}
              helperText={errors.lastname}
              size="small"  // Tamaño pequeño
              variant="standard"
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px',  // Ajusta el relleno interno del TextField
                  '& fieldset': {
                    borderColor: 'rgba(0, 255, 0, 0.5)',  // Cambia el color del borde
                    borderWidth: '1px',  // Ancho del borde
                    borderRadius: '4px',  // Radio de borde
                  },
                },
                '& .MuiOutlinedInput-input': {
                  py: '0px',  // Ajusta el padding vertical del texto dentro del TextField
                },
                marginBottom: '10px',
              }}
            />
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Usuario (Correo electrónico usuario@correo.com)
            </Typography>
            <TextField
              fullWidth
              name="username"
              value={formData.username}
              onChange={this.handleChange}
              error={!!errors.username}
              helperText={errors.username}
              size="small"  // Tamaño pequeño
              variant="standard"
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px',  // Ajusta el relleno interno del TextField
                  '& fieldset': {
                    borderColor: 'rgba(0, 255, 0, 0.5)',  // Cambia el color del borde
                    borderWidth: '1px',  // Ancho del borde
                    borderRadius: '4px',  // Radio de borde
                  },
                },
                '& .MuiOutlinedInput-input': {
                  py: '0px',  // Ajusta el padding vertical del texto dentro del TextField
                },
                marginBottom: '10px',
              }}
            />
          </Box>
          <Box>
            <Box mb={2}>
              <Typography variant="h6" component="label" htmlFor="specialityId">
                Especialidad 
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                  <FormControl fullWidth error={!!errors.specialityId}>
                    <select 
                    name = "specialityId"
                    value={formData.specialityId}
                    id="specialityId" onChange={this.handleChange} 
                    style={{ width: '100%', fontSize: '1.4rem', height: '32px', marginBottom: '10px', }}>
                      {specialityList && specialityList.map((speciality, index) => (
                        <option key={index} value={speciality.specialityId}>
                          {speciality.specialityName}
                        </option>
                      ))}
                    </select>
                    {errors.specialityId && (
                    <Typography className={classes.errorMessage}>{errors.specialityId}</Typography>
                  )}
                </FormControl>  
              </Grid>
            </Grid>
        </Box>
        <Box>
            <Box mb={2}>
              <Typography variant="h6" component="label" htmlFor="specialityId">
              Tipo de evaluación que puede realizar
              </Typography>
            </Box>
          <Table>
          <TableBody sx={{ '& .MuiTableRow-root': { height: 'auto' } }}>
            {evaluationTypeList.length > 0 ? (
              evaluationTypeList.map((item, index) => (
                <TableRow sx={{ padding: '0', border: 'none' }} key={index} >
                <TableCell sx={{ padding: 0 }}>{item.typeDescription}</TableCell>
                <TableCell sx={{ padding: 0 }}>
                    <Checkbox
                      color="success"
                      checked={formData.evaluationType.includes(item.typeId)}
                      onChange={() => this.handleCheckboxChange(item.typeId)}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">No hay tipos de evaluación definidos</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

       </Box>        

          
        {Number(this.state.userrole)===99 && (
        <>
       <Box mb={2}> 
        <Box mb={2}>
          <Typography variant="h6" component="label" htmlFor="companyId">
            Entidad a la que pertenece
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
          <FormControl fullWidth error={!!errors.companyId}>
            <select 
            value={formData.companyId}
            name="companyId"
            id="companyId" onChange={this.handleChange} 
            style={{ width: '100%', fontSize: '1.4rem', height: '32px' }}>
              {companyList && companyList.map((company, index) => (
                <option key={index} value={company.companyId}>
                  {company.companyName}
                </option>
              ))}
            </select>
            {errors.companyId && (
            <Typography className={classes.errorMessage}>{errors.companyId}</Typography>
          )}
        </FormControl>  
          </Grid>
        </Grid>
      </Box>
    <Box>
        <Grid container spacing={1}>
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
        
        <Box mb={2}>
          <Typography variant="h6" component="label" htmlFor="companyId">
          Tipo de evaluación que puede realizar
          </Typography>
        </Box>
            <RadioGroup aria-label="Speakerdo" name="userRole"
             value={this.state.formData.rol} onChange={this.handleRole}
             style={{ display: 'flex', flexDirection: 'row' }}>
                  {this.state.roles &&  this.state.roles.map((rol) => (
                    
                    <FormControlLabel
                      key={rol.rolId}
                      value={rol.rolId}
                      control={<BlueRadio />}
                      checked={this.state.formData.rol == rol.rolId}
                      label={rol.perfil} />
                  ))}
           </RadioGroup>
        </Grid>
      </Grid>
      </Box>


      </>
        )
        }


          {/* <Box >
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Contraseña
            </Typography>
            <TextField
              fullWidth
              name="password"
              type="password"
              value={formData.password}
              InputProps={{
                readOnly: true,
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Box> */}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
      <Button
          className='btnPrimary'
          onClick={this.handleCloseCreateEvaluator}
          color="primary"
          sx={{
            width: { xs: '100%', sm: 'auto' },
            mb: { xs: 1, sm: 0 }, // Añadir un margen en la parte inferior en pantallas pequeñas
          }}
        >
          Salir
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>

{/* DIALOGO DE VISUALIZACIÓN */}
<Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              // overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >

            <DialogTitle className='dialogTttle' id="alert-dialog-title">TRABAJO INSCRITO</DialogTitle>
            <DialogContent style={{ paddingLeft: '2rem' }}>
              <DialogContentText id="alert-dialog-description">
               {this.state.pdfBlobUrl && <iframe src={this.state.pdfBlobUrl} width="100%" height="600px"></iframe>}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluatorsList    : state.evaluators,
  companyList       : state.SUSILists.company,
  evaluationTypeList: state.SUSILists.evaluationType,
  specialityList    : state.SUSILists.speciality,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(Evaluator);