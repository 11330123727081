import React from "react";
import { connect } from "react-redux";
import { fetchAssignedAssets, createInventoryCheckList } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import AssignedAsset from "./AssignedAsset";
import { fetchRazonReimpresionPlaca } from "../../redux/actions/SUSIListsActions";
import { fetchCategory } from "../../redux/actions/SUSIListsActions";
import { fetchPresentation } from "../../redux/actions/SUSIListsActions";
import { fetchCity } from "../../redux/actions/SUSIListsActions";
import { fetchCompany } from "../../redux/actions/SUSIListsActions";
import { fetchEvaluationType } from "../../redux/actions/SUSIListsActions";
import { fetchSpeciality } from "../../redux/actions/SUSIListsActions";
import { fetchStepState } from "../../redux/actions/SUSIListsActions";
import { fetchMember } from "../../redux/actions/membersActions";

import { Alert, AlertTitle } from "@material-ui/lab";

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchSyncWithSIAF } from "../../redux/actions/syncWithSIAFActions";
import { updateParamSearch } from "../../redux/actions/paramsSearchAction";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";

import { DataGrid } from "@material-ui/data-grid";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { NavLink } from "react-router-dom";
import { fetchWithoutFillingOuts } from "../../redux/actions/withoutFillingOutActions";
import Button from "@material-ui/core/Button";
import axios from "axios";

import FontAwsome_check from "../../assets/img/check_circle.svg";

class AssignedAssetsList extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      paramsFecthSyncSIAF: {
        identification: props.user.numero_documento,
        user_susi_id: props.user.user_susi_id,
        user_name: props.user.fullName, //TLA Cambio
        user_mail: props.user.email
      },
      paramsFetchAssets: {
        identification: props.user.numero_documento,
        page: this.props.assignedAssets.page,
        sectional: "",
        estado: "",
        descripcionBien: "",
        dependencia: "",
        placa: "",
        novedad: "",
        competition_id : "",
        competition_type : "",
        competition_title : "",
        competition_description : "",
        competotion_aimed_at : "",
        competition_thematic_areas : "",
        competition_categories : "",
        competition_image : "",
        competition_background : "",
        competition_state : "",
        orderDescripcion: "",
        orderPlaca: "",
        orderSerie: "",
      },
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      prevY: 0,
      heightWindow: window.innerHeight - 250,
      showDlgCreateChecklist: false,
      showDlgInventWithout: false,
      showDlgWithout: false,
      showRepeated: true,
      showSyncFail: true,
      checklistSaved: '',
      inventoryPresent: false,
      errorCreateInventory: '',
      checklistSavedDate: '',
      showButtonsCreate: true,
      savingInventory: false,
      countWithoutUpdate: 0,
      WithoutUpdateLoad: true,
      WithoutUpdate: {},
      listLoad: true,
      syncRepeated: [],
      disabledButton: false,
      loadingAssets: false,
      cheklistExist: []
    };
    this.Without = 0;
    this.fecthWithout = 0;
    this.pagination = 1;
    this.fetchMore = this.fetchMore.bind(this);
    this.columnsWithout = [
      { field: 'competition_title', headerName: 'Titulo', width: 220 },
      { field: 'competition_description', headerName: 'Descripción', width: 150 },
      { field: 'competition_aimed_at', headerName: 'Dirigido a', width: 250 },
      { field: 'competition_background', headerName: 'Fondo', width: 117 },
      { field: 'competition_type', headerName: 'Tipo', width: 130 }
    ];
    this.columnsAssignedAssets = [
      { field: 'competition_title', headerName: 'Titulo', width: 220 },
      { field: 'competition_description', headerName: 'Descripción', width: 150 },
      { field: 'competition_aimed_at', headerName: 'Dirigido a', width: 250 },
      { field: 'competition_background', headerName: 'Fondo', width: 117 },
      { field: 'competition_type', headerName: 'Tipo', width: 130 }
    ];
  }

  componentDidMount() {
    console.log("TLA monta el componente antes de fetchassigned 110");
    console.log(this.props.assignedAssets);
    console.log(this.props.assignedAssets.assets.length);

// TLA quita llamados a back    
//    this.props.dispatch(fetchSyncWithSIAF(this.state.paramsFecthSyncSIAF));
    this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets, true));
    this.props.dispatch(fetchCategory());
    this.props.dispatch(fetchCompany());
    this.props.dispatch(fetchEvaluationType());
    this.props.dispatch(fetchSpeciality());
    this.props.dispatch(fetchPresentation());
    this.props.dispatch(fetchCity());
    this.props.dispatch(fetchMember());
    this.props.dispatch(fetchStepState());
    // this.props.dispatch(fetchAssetsStatus());
    // this.props.dispatch(fetchChangesTypes());
    // this.props.dispatch(fetchAssetStages());
    // this.props.dispatch(fetchMissingReasons());
    // this.props.dispatch(fetchLocations());
    // this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    // this.props.dispatch(fetchRazonReimpresionPlaca());
    console.log("TLA termina monta el componente antes de fetchassigned 110");
    console.log(this.props.assignedAssets);
    console.log(this.props.assignedAssets.assets.length);
    this.setState({disabledButton:false})
  }

  componentDidUpdate(prevProps) {
    //Uso tipico (no olvides de comparar las props):    
    //if (this.props.assignedAssets.asset !== prevProps.assignedAssets.asset) {
      console.log("ACTUALIZADO ");
      console.log(this.props.assignedAssets);
      if(this.props.assignedAssets.assets){
        console.log("LONGITUD");
        console.log(this.props.assignedAssets.assets.length);
  
      }

      // this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
      // this.props.dispatch(updateParamSearch(this.state.paramsFetchAssets));     
      // this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
    //}
  }

  getnameLocation(idLocation) {
    let dataname = this.props.locations.filter(t => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
    return '';
  }

  getURLAsset(assetFile) {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  }

  getSectionalName(sectionalName, cont, idSeccional) {
    let seccionalCount = 0
    for (let i = 0; i < this.props.assignedAssets.countSeccional.length; i++) {
      const element = this.props.assignedAssets.countSeccional[i];
      if (element.ASAS_SECT_ID == idSeccional) {
        seccionalCount = element.SECTGROUP
      }
    }

    if (cont != 0) {
      let diferent =
        this.props.assignedAssets.assets[cont - 1].seccional !==
        this.props.assignedAssets.assets[cont].seccional;
      return diferent ? `${sectionalName} (${seccionalCount})` : "";
    }

    return `${sectionalName} (${seccionalCount})`;
  }

  // updateParamsAssets(secc, dep, est, desBien, placa, novedad, serie, orders = []) {
  //   this.setState(
  //     {
  //       paramsFetchAssets: {
  //         identification: this.props.user.numero_documento,
  //         page: 1,
  //         sectional: secc,
  //         dependencia: dep,
  //         estado: est,
  //         descripcionBien: desBien,
  //         placa: placa,
  //         novedad: novedad,
  //         serie,
  //         orderDescripcion: orders[0],
  //         orderPlaca: orders[1],
  //         orderSerie: orders[2],
  //         orderRegional: orders[3],
  //       },
  //     },
  //     () => {
  //       this.props.assignedAssets.assets = undefined
  //       this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
  //       this.props.dispatch(updateParamSearch(this.state.paramsFetchAssets));
  //     }
  //   );
  // }

  assetIsOut(placa) {
    if (this.props.outList.withoutFillingOuts.length > 0) {
      let isOut = this.props.outList.withoutFillingOuts.find(i => i.placa.includes(placa));
      return isOut ? true : false;
    }
    return false;
  }

  /* static getDerivedState */

  handleScroll(e) {
    const top = Math.trunc(e.target.scrollHeight - e.target.scrollTop);
    const bottom = e.target.clientHeight;

    if (bottom === top) {
      let jasper = Object.assign({}, this.state.paramsFetchAssets);
      jasper.page = this.props.assignedAssets.page + 1;
      this.props.dispatch(fetchAssignedAssets(jasper));
    }
  }

  fetchMore() {
    setTimeout(() => {
      let jasper = Object.assign({}, this.state.paramsFetchAssets);
      jasper.page = this.props.assignedAssets.page + 1;
      this.props.dispatch(fetchAssignedAssets(jasper));
      this.props.dispatch(updateParamSearch(jasper));
    }, 1500);
  }

  /* checkList */

  handleCloseChecklist = () => {
    if (this.state.checklistSaved > 0) { this.setState({ showDlgInventWithout: true }) }
    this.setState({ showDlgCreateChecklist: false });
    this.setState({ showButtonsCreate: true })
    this.setState({ errorCreateInventory: '' })
  }

  handleCreateCheckList = () => {
    this.props.dispatch(fetchWithoutFillingOuts(this.props.user.numero_documento));
    this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    this.setState({ showDlgCreateChecklist: true });
    this.setState({ loadingAssets: true });
    setTimeout(() => {
      this.setState({ loadingAssets: false });
    }, 3000);
  };

  handleCreateInventoryChecklist = async () => {
    console.log("TLA handle create inventroty check");
    this.setState({ savingInventory: true });
    let params = {
      identification: this.props.user.numero_documento
    }
    this.setState({ savingInventory: true });
    this.setState({ showButtonsCreate: false });
    let resultCreate = await createInventoryCheckList();
    console.log("competitions",resultCreate);
    if (resultCreate.ok) {
      this.setState({ savingInventory: false });
      this.setState({ inventoryPresent: true });
      if (resultCreate.record && resultCreate.dateRecord) {
        this.setState({ checklistSaved: resultCreate.record });
        this.setState({ checklistSavedDate: new Intl.DateTimeFormat('es-ES', { dateStyle: 'long' }).format(new Date(resultCreate.dateRecord)) })
      }
      let paramActual = {
        identification: this.props.user.numero_documento,
        user_susi_id: this.props.user.user_susi_id,
        page: 1
      };
      this.props.dispatch(fetchAssignedAssets(paramActual));
      //this.props.dispatch(fetchInventoryHistory({ user_susi_id: params.user_susi_id }));
      this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
    } else {
      this.setState({ savingInventory: false });
      this.setState({ errorCreateInventory: resultCreate.error });
      this.setState({ showButtonsCreate: false });
    }
  }

  renderDlgCreateChecklist = (props) => {
    props.assignedAssets.assets.map((asset, count) => {
      if (asset.location_id != null || asset.location_id != undefined) {
        asset['location_id_full'] = props.locations.find(i => i.id == asset.location_id).fullname
      }

      if (asset.location_complement != null) {
        if (asset.location_id != null) {
          asset['location_id_full'] += asset['location_complement'] != null ? ` ${asset['location_complement']}` : ''
        } else {
          asset['location_id_full'] = asset['location_complement'] != null ? ` ${asset['location_complement']}` : ''
        }
      }

      if (asset.change_type_id != null) {
        asset['change_type_id_asset'] = getNameOptionSUSIList(asset.change_type_id, props.changesTypes)
      }
      if (asset.estadoBienSUSI != null) {
        asset['estadoBienSUSI_asset'] = getNameOptionSUSIList(asset.estadoBienSUSI, props.assetsStatus)
      }

      return null
    })

    return (
      <Dialog className='checklistDialogGeneral'
        open={this.state.showDlgCreateChecklist} maxWidth={this.state.checklistSaved.length == 0 ? "md" : 'sm'}>
        {this.props.withoutFillingOuts.isFetching || this.state.checklistSaved.length == 0 ?
          <DialogTitle style={{ fontWeight: 'bold', padding: '16px 24px 2px 20px', color: '#5f6273' }}
            id="form-dialog-title">
            Reporte la verificación física de su inventario individual de bienes
          </DialogTitle> : ''}
        <DialogContent>
          {this.state.checklistSaved.length == 0 ? (
            <>
              <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
              Apreciad@ servidor@, la verificación de todos los bienes registrados está completa. Antes de generar
               su reporte, revise una vez más que la información sea <span style={{ fontWeight: 'bold' }}>correcta y verídica.</span>
              </DialogContentText>
              <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
              Recuerde que como servidor público es su deber responder por la conservación, salvaguarda, buen uso y rendición oportuna de los bienes a su cargo. Ley 1952 de 2019 (Código General Disciplinario
              </DialogContentText>

              {this.state.loadingAssets ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress className="circular-progress" />
                </div>
                :
                <div style={{ height: '18.188rem', width: '100%', display: 'block', padding: '10px 20px' }}>
                  <DataGrid pagination rows={this.props.assignedAssets.assets}
                    columns={this.columnsAssignedAssets} autoHeight={false} />
                </div>
              }
              <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
              <span style={{ fontWeight: 'bold' }}>Cláusula de responsabilidad</span>
              </DialogContentText>
              <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
              Declaro que soy responsable por la conservación, salvaguarda y utilización de los bienes a mi cargo,
              en virtud de la Ley 1952 de 2019 (Código General Disciplinario) y la Resolución 0-1166 de 2021.
              </DialogContentText>
              <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
              Me comprometo a rendir cuenta oportuna de las novedades, aplicando los procedimientos para mantener 
              actualizado mi inventario individual de bienes, para reportar las fallas, daños, pérdidas o 
              hurtos, y para las verificaciones físicas por parte de los servidores que ejercen controles sobre 
              ellos, so pena de incurrir en faltas sancionadas disciplinariamente cuando no exista justa causa.
              </DialogContentText>
              {/* <DialogContentText style={{ color: '#5f6273', fontWeight: '400', fontSize: '0.875rem' }}>
                ¿Está seguro de finalizar el proceso?
              </DialogContentText> TLA Cambia texto*/}
              
              {this.state.inventoryPresent ? <Alert severity="info">El inventario ha sido presentada</Alert> : null}
              {this.state.errorCreateInventory.length > 0 ? <Alert severity="error">Error al crear el inventario: {this.state.errorCreateInventory}</Alert> : null}
            </>
          ) : (
            <div className='InventarioCreado'>
              <img src={FontAwsome_check} style={{ marginTop: '1rem' }} alt="FontAwsome check" />
              <DialogContentText style={{ fontSize: '1.25rem', fontWeight: '800', color: '#5f673', marginTop: '1rem', marginBottom: 0 }}>
              <span style={{ fontWeight: 'bold' }}>Se ha generado el reporte de verificación física de inventario individual de bienes devolutivos</span>
              </DialogContentText>
              <DialogContent>
                {/* <Grid container justifyContent='flex-start'>
                  <Grid item xs sm={6} style={{ textAlign: 'end' }}>
                    <DialogContentText>
                      <b style={{ fontSize: '1rem', fontWeight: '800' }}>Número Inventario:</b>
                    </DialogContentText>
                  </Grid>
                  <Grid item xs sm style={{ textAlign: 'start' }}>
                    <DialogContentText className='dialogText'>
                      {this.state.checklistSaved}
                    </DialogContentText>
                  </Grid>
                </Grid> */}

                <Grid container justifyContent='flex-start'>
                  <Grid item xs sm={6} style={{ textAlign: 'end' }}>
                    <DialogContentText>
                      <b style={{ fontSize: '1rem', fontWeight: '800' }}>Fecha Presentación:</b>
                    </DialogContentText>
                  </Grid>
                  <Grid item xs sm style={{ textAlign: 'start' }}>
                    <DialogContentText className='dialogText'>
                      {this.state.checklistSavedDate}
                    </DialogContentText>
                  </Grid>
                </Grid>

              </DialogContent>
            </div>
          )}
        </DialogContent>
        {this.state.showButtonsCreate ? (
          <DialogActions style={{ marginBottom: '1rem', marginRight: '2rem' }}>
            <Button onClick={this.handleCloseChecklist} className="btnSecundary w-auto">
              NO
            </Button>
            <Button onClick={this.handleCreateInventoryChecklist} className="btnPrimary w-auto">
              SI, Reportar
            </Button>
          </DialogActions>
        ) : (
          <DialogActions style={{ justifyContent: 'center' }}>
            {this.state.savingInventory ? (
              <Button className='btnPrimary w-auto' color="primary">
                GUARDANDO...
              </Button>) : (
              <Button className='btnPrimary w-auto' style={{ marginBottom: '2rem' }} onClick={() => { this.handleCloseChecklist() }}>
                Ok
              </Button>
            )}
          </DialogActions>
        )
        }
      </Dialog >
    );
  }


  renderDialogInventWithout = () => {
    return (
      <Dialog className="DialogInventWithout" open={this.state.showDlgInventWithout}>
        <DialogTitle id="form-dialog-title">
          Apreciad@ servidor@
        </DialogTitle>
        <DialogContentText className='dialogText'>
        Diríjase al módulo <span style={{ fontWeight: 'bold' }}>Evaluaciones</span> para reportar
        los bienes que tiene, pero no están registrados en su inventario.
        </DialogContentText>
        <DialogContent style={{ alignSelf: 'end', justifyContent: 'space-evenly' }}>
          <Grid container justifyContent='space-evenly' direction="row">
            <Grid item xs="auto">
              <NavLink to="/toreport">
                <Button className="btnSecundary">
                  No
                </Button>
              </NavLink>
            </Grid>
            <Grid item xs="auto">
              <NavLink to="/toleftover">
                <Button className="btnPrimary">
                  Si
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  /* Without */

  togleeDlWithout = () => {
    this.getWithoutUpdate(this.props)
    const toglee = !this.state.showDlgWithout;
    this.setState(() => ({
      showDlgWithout: toglee
    }))
  };

  renderDialogWithout = () => {
    return (
      <Dialog open={this.state.showDlgWithout} maxWidth="md">
        <DialogTitle style={{ fontWeight: 'bold', padding: '16px 24px 2px 25px', color: '#5f6273' }}
          id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>Reporte la verificación física de su inventario individual de bienes</span>
        </DialogTitle>
        {!this.state.WithoutUpdateLoad ?
          (<>
            <DialogContentText style={{ color: '#5f6273', padding: '10px 26px' }}>
            Apreciad@ servidor@, para generar el reporte de su inventario individual de bienes devolutivos debe diligenciar la ubicación, el estado y la verificación física de todos los bienes registrados a su cargo.
            </DialogContentText>
            <DialogContentText style={{ color: '#5f6273', padding: '10px 26px' }}>
              Los siguientes bienes aún no cuentan con la información completa:
            </DialogContentText>
            <div style={{ height: '18.188rem', width: '100%', display: 'block', padding: '10px 20px' }}>
              <DataGrid pagination rows={this.state.WithoutUpdate} columns={this.columnsWithout} autoHeight={false} />
            </div>

          </>) : (<>
            <DialogContent style={{ color: '#5f6273', padding: '10px 26px', textAlign: 'center' }}>
              <CircularProgress className="circular-progress" />
            </DialogContent>
          </>)
        }
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button className='btnSecundary w-auto' style={{ marginBottom: '2rem' }} onClick={() => this.togleeDlWithout()}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getWithoutUpdate = async (props) => {
    if (props.user.numero_documento != undefined) {
      await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/WithoutUpdate?identification=' + props.user.numero_documento)
        .then(res => {
          res.data.data.map((without, count) => {
            without['id'] = count
            if (without.ASAS_ASSG_ID != null) {
              without['ASAS_ASSG_ID'] = props.stages.stages.find(i => i.id == without.ASAS_ASSG_ID).name
            }

            if (without.ASAS_LOCATION_ID != null) {
              without['ASAS_LOCATION_ID'] = props.locations.find(i => i.id == without.ASAS_LOCATION_ID).fullname
            }

            if (without.ASAS_LOCATION_DESCRIPTION != null) {
              if (without.ASAS_LOCATION_ID != null) {
                without['ASAS_LOCATION_ID'] += without['ASAS_LOCATION_DESCRIPTION'] != null ? without['ASAS_LOCATION_DESCRIPTION'] : ''
              } else {
                without['ASAS_LOCATION_ID'] = without['ASAS_LOCATION_DESCRIPTION'] != null ? without['ASAS_LOCATION_DESCRIPTION'] : ''
              }
            }

            if (without.ASAS_CHTY_ID != null) {
              without['ASAS_CHTY_ID'] = getNameOptionSUSIList(without.ASAS_CHTY_ID, this.props.changesTypes)
            }
            if (without.ASAS_ASST_ID != null) {
              without['ASAS_ASST_ID'] = getNameOptionSUSIList(without.ASAS_ASST_ID, this.props.assetsStatus)
            }
            return null
          });
          this.setState({ WithoutUpdate: res.data.data })
          this.setState({ WithoutUpdateLoad: false })
        })
        .catch(error => {
          console.error('Error en AssignedAssetsList/getWithoutUpdate', error);
        })
    }
  }

  /* Count Without */

  // getCountWithoutUpdate = async (numero_documento) => {
  //   if (numero_documento != undefined) {
  //     this.setState({ listLoad: true })
  //     await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/countWithoutUpdate?identification=' + numero_documento)
  //       .then(res => {
  //         this.setState({ cheklistExist: res.data.cheklistExist })
  //         this.setState({ WithoutUpdateLoad: true })
  //         this.setState({ countWithoutUpdate: res.data.data })
  //         this.setState({ listLoad: false })
  //       })
  //       .catch(error => {
  //         console.error('Error en getCountWithoutUpdate: ', error);
  //       })
  //     return null
  //   }
  // }

  // setStateGetCountWithoutUpdate = () => {
  //   if (this.Without) { this.setState({ listLoad: true }) }
  //   setTimeout(async () => {
  //     this.setState({ stateGetCountWithoutUpdate: true })
  //     this.getCountWithoutUpdate(this.props.user.numero_documento)
  //   }, 1000);
  // }

  validateDisabledButton = (asset) => {
    if (asset.date_checklist != null && new Date(asset.date_checklist).getFullYear() == new Date().getFullYear() && !this.state.disabledButton ||
      this.state.cheklistExist[0] != null && this.state.cheklistExist.length > 0 && !this.state.disabledButton) {

      this.setState({ disabledButton: true })
    }
  }


  /* Fails */

  // renderDialogRepeats = () => {
  //   return (
  //     <Dialog open={this.state.showRepeated}>
  //       <DialogTitle style={{ fontWeight: 'bold', padding: '16px 24px 2px 25px', color: '#5f6273' }}
  //         id="form-dialog-title">
  //         Bienes repetidos
  //       </DialogTitle>
  //       <DialogContentText style={{ color: '#5f6273', padding: '10px 26px' }}>
  //         Señor(a) usuario se han encontrado bienes repetidos a su nombre en SIAF, por lo que no podra
  //         acceder a la plataforma.
  //       </DialogContentText>
  //       <DialogActions style={{ justifyContent: 'center' }}>
  //         <Button onClick={() => {
  //           window.sessionStorage.clear();
  //           window.location.href = '/';
  //         }} className='btnPrimary'>
  //           Salir de la aplicación
  //         </Button>
  //       </DialogActions>
  //     </Dialog >
  //   )
  // }

  // renderDialogSyncFail = () => {
  //   return (
  //     <Dialog open={this.state.showSyncFail}>
  //       <DialogTitle style={{ fontWeight: 'bold', padding: '16px 24px 2px 25px', color: '#5f6273' }}
  //         id="form-dialog-title">
  //         Fallo al sincronizar con SIAF
  //       </DialogTitle>
  //       <DialogContentText style={{ color: '#5f6273', padding: '10px 26px' }}>
  //         Señor(a) usuario ha no ha sido posible sincronizarlo con la plataforma de SIAF, por favor comuniquese con soporte para
  //         encontrar la posible solución al problema.
  //       </DialogContentText>
  //       <DialogActions style={{ justifyContent: 'center' }}>
  //         <Button onClick={() => {
  //           window.sessionStorage.clear();
  //           window.location.href = '/';
  //         }} className='btnPrimary'>
  //           Salir de la aplicación
  //         </Button>
  //       </DialogActions>
  //     </Dialog >
  //   )
  // }

  render() {

    window.addEventListener('resize', () => {
      if ((window.innerHeight - 250) != this.state.heightWindow) {
        this.setState({ heightWindow: window.innerHeight - 250 })
      }
    });

    // if (this.props.syncRepeated != undefined && this.props.syncRepeated != 0) {
    //   return (
    //     <>
    //       {this.renderDialogRepeats()}
    //     </>
    //   )
    // } else {
    //   if (this.props.syncFail != undefined && this.props.syncFail != 0) {
    //     return (
    //       <>
    //         {this.renderDialogSyncFail()}
    //       </>
    //     )
    //   } else 
    //   if (this.props.SyncSIAF === true)
    //   {
        // if (this.props.assetsStatus.length  && 
        //   this.props.assignedAssets.assets != undefined) { 
        //   if (this.Without == 0) {
        //     this.setStateGetCountWithoutUpdate()
        //     this.Without++;
        //   }
        //   }

          return (
            <Grid container justifyContent="flex-end">
              {/* <Grid item xl="auto">
                {!this.props.assignedAssets.isFetching && this.state.countWithoutUpdate === 0 && !this.state.listLoad ?
                  <div style={{ backgroundColor: this.state.disabledButton ? '#6ce1f5' : '#445DC9' }} className='buttonCheckInventory'>
                    <Button className="buttonText" style={{ color: this.state.disabledButton ? '#445DC9' : '#fff' }} onClick={() => this.handleCreateCheckList()} disabled={this.state.disabledButton}>
                      <CheckCircleOutlineIcon style={{ color: this.state.disabledButton ? '#445DC9' : '#fff', marginRight: '2px' }} />
                      {this.state.disabledButton ? 'Inventario verificado' : 'Reportar Inventario'}
                    </Button>
                  </div>
                  :
                  <div style={{ backgroundColor: '#DAE2FC' }} className='buttonCheckInventory'>
                    <Button className="buttonText" style={{ color: '#879EE3' }} onClick={() => this.togleeDlWithout()}>
                      Reportar Inventario
                      {this.state.listLoad ?
                        <div className="divCountLoad" style={{ padding: '5px' }}>
                          <CircularProgress style={{ width: '10px', height: '10px', color: '#ffff' }} />
                        </div>
                        :
                        <div className="divCountLoad" style={{ padding: '1px 5px' }}>
                          {this.state.countWithoutUpdate}
                        </div>
                      }
                    </Button>
                  </div>
                }
              </Grid> */}



              <Grid item xs={12}>
                {this.props.assignedAssets.assets.length > 0 ? (
                  <InfiniteScroll
                    dataLength={this.props.assignedAssets.assets.length}
                    next={this.fetchMore}
                    className="InfiniteScroll"
                    hasMore={this.props.assignedAssets.hasMore}
                    height={this.state.heightWindow}
                    loader={
                      <div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                        <CircularProgress className="circular-progress" />
                      </div>
                    }
                  >
                    {this.props.assignedAssets.assets.map((asset, cont) => {
                      this.validateDisabledButton(asset)
                      return (
                        <div key={asset.competition_id}>
                          {/* <h5 className="seccional">{this.getSectionalName(asset.nombreSeccional, cont, asset.seccional)}</h5> */}
                          <AssignedAsset
                            key={asset.competition_title + cont}
                            data={asset}
                            isOut={this.assetIsOut(asset.competition_title)}
                            autoVerification={this.props.assignedAssets.count > this.props.assignedAssets.verificationLimit}
                            num={cont + 1}
                            url_imagen={this.getURLAsset(asset.category_icon)}
                            assetsStatus={this.props.assetsStatus}
                            changesTypes={this.props.changesTypes}
                            user={this.props.user}
                            missingReasons={this.props.missingReasons}
                            locations={this.props.locations}
                            ubication={this.getnameLocation(asset.location_id)}
                            propsDad={this.props}
                            numero_documento={this.props.user.numero_documento}
                            getCountWithoutUpdate={this.setStateGetCountWithoutUpdate}
                          />
                        </div>
                      )
                    })}
                  </InfiniteScroll>
                ) : this.props.assignedAssets.isFetching === false && this.props.assignedAssets.assets.length === 0 ? (
                  <Grid item xs={12} className="container" style={{ padding: "20px" }}>
                    <Alert severity="info">
                      <AlertTitle>Info</AlertTitle>
                      No hay resultados para la busqueda.
                    </Alert>
                  </Grid>
                ) 
                :
                  <div className="container" style={{ margin: '8.375rem auto' }}>
                    <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                      <h4 className="sincronizandoTittle">Obteniendo Eventos...</h4>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress className="circular-progress" />
                      </div>
                    </div>
                  </div>
                } 
              </Grid>

              {/* {this.renderDlgCreateChecklist(this.props)}
              {this.renderDialogWithout()}
              {this.renderDialogInventWithout()} */}
            </Grid>
          )
        } 
        // else {

        //   return (
        //     <div className="container" style={{ margin: '8.375rem auto' }}>
        //       <div style={{ justifyContent: 'center', textAlign: 'center' }}>
        //         <h4 className="sincronizandoTittle">Obteniendo bienes...</h4>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //           <CircularProgress className="circular-progress" />
        //         </div>
        //       </div>
        //     </div>
        //   )
        // }
    
      } 
      // else {
      //   return (
      //     <div className="container" style={{ margin: '8.375rem auto' }}>
      //       <div style={{ justifyContent: 'center', textAlign: 'center' }}>
      //         <h4 className="sincronizandoTittle">Sincronizando con SIAF…</h4>
      //         <div style={{ display: "flex", justifyContent: "center" }}>
      //           <CircularProgress className="circular-progress" />
      //         </div>
      //       </div>
      //     </div>
      //   )
      // }
    //}
//  }
//}

const mapStateToProps = (state) => {
  console.log("TLA state en assignedassetslist"); // TLA
  console.log(state);
  return {
    outList: state.withoutFillingOut,
    assignedAssets: state.assignedAssets,
    user: state.user,
    locations: state.locations.locations,
    members: state.members,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    categories:   state.SUSILists.categories,
    presentation: state.SUSILists.presentation,
    city        : state.SUSILists.city,
    missingReasons: state.SUSILists.missingReasons,
    rePrintReasons: state.SUSILists.reprintReasons,
    myInscriptions: state.inventoryHistory.history,
    sectionalsUser: state.sectionals,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,
    syncRepeated: state.syncSIAF.syncRepeated,
    syncFail: state.syncSIAF.syncFail,
    withoutFillingOuts: state.withoutFillingOut,
    params: state.getActualParams,
    countSeccional: state.countSeccional,
    notify: state.callNotifys,
  }
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  AssignedAssetsList
);
