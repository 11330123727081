import {FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_ERROR,
        FETCH_PRESENTATION_SUCCESS, FETCH_PRESENTATION_ERROR,
        FETCH_CITY_SUCCESS, FETCH_CITY_ERROR,
        FETCH_COMPANY_SUCCESS, FETCH_COMPANY_ERROR,
        FETCH_EVALUATIONTYPE_SUCCESS, FETCH_EVALUATIONTYPE_ERROR,
        FETCH_SPECIALITY_SUCCESS, FETCH_SPECIALITY_ERROR,
        FETCH_THEMATICAREA_SUCCESS, FETCH_THEMATICAREA_ERROR,
        FETCH_STEPSTATE_SUCCESS, FETCH_STEPSTATE_ERROR,
    FETCH_INVENTORY_OFFICERS_SUCCESS, FETCH_INVENTORY_OFFICERS_ERROR,
    FETCH_ASSETS_STATUS_SUCCESS, FETCH_ASSETS_STATUS_ERROR, FETCH_CHANGES_TYPES_SUCCESS,
    FETCH_CHANGES_TYPES_ERROR, FETCH_MISSING_REASONS_SUCCESS, FETCH_MISSING_REASONS_ERROR,
    FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS, FETCH_ASSETS_SURPLUS_TYPELIST_ERROR, FETCH_TYPE_TRANSACT_TYPELIST_REQUEST, FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS, FETCH_TYPE_TRANSACT_TYPELIST_ERROR, FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST, FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS, FETCH_TYPE_DOCUMENT_TYPELIST_ERROR, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR, FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST, FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS, FETCH_TRANSACT_TYPES_DOCUMENT_ERROR, FETCH_REPRINT_REASON_SUCCESS, FETCH_REPRINT_REASON_REQUEST, FETCH_REPRINT_REASON_ERROR
} from "../types";
import {REACT_APP_BACKEND_URL} from "../../config";


import axios from 'axios';

export const getptionSUSIList = (value, SUSIlist) => {
    if (SUSIlist.length > 0) {
        let options = SUSIlist.filter(optionSUSI => optionSUSI.id == value);
        if (options[0]) {
            return options[0];
        }
        return "";
    } else {
        return "";
    }
}

export const getIdOptionSUSIList = (value, SUSIlist) => {
    if (SUSIlist.length > 0) {
        let options = SUSIlist.filter(optionSUSI => optionSUSI.name == value);
        if (options[0]) {
            return options[0].id;
        }
        return "";
    } else {
        return "";
    }
}

export const getNameOptionSUSIList = (id, SUSIlist) => {

    if (SUSIlist.length > 0) {
        let options = SUSIlist.filter(optionSUSI => optionSUSI.id == id);
        if (options[0]) {
            return options[0].name;
        }
        return "";
    } else {
        return "";
    }
}

export const fetchInventoryOfficers = (params) => (dispatch) => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_ROL_INVENTARIOS')
        .then(res => {
            const documento=params;
            var results = res.data.filter(function (rol) { return rol.value == documento; });
            var perfil = (results.length > 0) ? 2 : 1;    
            dispatch({
                type: FETCH_INVENTORY_OFFICERS_SUCCESS,
                payload: {
                    officers: perfil
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de Estados de Bienes: ", error);
            dispatch({
                type: FETCH_INVENTORY_OFFICERS_ERROR,
                error: error.toString()
            })
        })
}

export const fetchAssetsStatus = (params) => (dispatch) => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_EST_BIEN')
        .then(res => {
            //console.warn("Estos son los ESTADOS DEL BIEN: ", res.data);
            dispatch({
                type: FETCH_ASSETS_STATUS_SUCCESS,
                payload: {
                    status: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de Estados de Bienes: ", error);
            dispatch({
                type: FETCH_ASSETS_STATUS_ERROR,
                error: error.toString()
            })
        })
}

export const fetchCategory = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=category', { headers })
        .then(res => {
            dispatch({
                type: FETCH_CATEGORY_SUCCESS,
                payload: {
                    categories: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de categorias: ", error);
            dispatch({
                type: FETCH_CATEGORY_ERROR,
                error: error.toString()
            })
        })
}

export const fetchEvaluationType = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=evaluationtype', { headers })
        .then(res => {
            dispatch({
                type: FETCH_EVALUATIONTYPE_SUCCESS,
                payload: {
                    evaluationType: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de tipos de evaluación: ", error);
            dispatch({
                type: FETCH_EVALUATIONTYPE_ERROR,
                error: error.toString()
            })
        })
}

export const fetchCompany = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=company', { headers })
        .then(res => {
            dispatch({
                type: FETCH_COMPANY_SUCCESS,
                payload: {
                    company: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de entidades: ", error);
            dispatch({
                type: FETCH_COMPANY_ERROR,
                error: error.toString()
            })
        })
}

export const fetchSpeciality = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=speciality', { headers })
        .then(res => {
            dispatch({
                type: FETCH_SPECIALITY_SUCCESS,
                payload: {
                    speciality: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de especialidades: ", error);
            dispatch({
                type: FETCH_SPECIALITY_ERROR,
                error: error.toString()
            })
        })
}

export const fetchThematicArea = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=thematicarea', { headers })
        .then(res => {
            dispatch({
                type: FETCH_THEMATICAREA_SUCCESS,
                payload: {
                    thematicArea: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de áreas temáticas: ", error);
            dispatch({
                type: FETCH_THEMATICAREA_ERROR,
                error: error.toString()
            })
        })
}

export const fetchStepState = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=stepstate', { headers })
        .then(res => {
            dispatch({
                type: FETCH_STEPSTATE_SUCCESS,
                payload: {
                    stepState: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de estados ", error);
            dispatch({
                type: FETCH_STEPSTATE_ERROR,
                error: error.toString()
            })
        })
}

export const fetchPresentation = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=presentation', { headers })
        .then(res => {
            dispatch({
                type: FETCH_PRESENTATION_SUCCESS,
                payload: {
                    presentation: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de tipo de presentación: ", error);
            dispatch({
                type: FETCH_PRESENTATION_ERROR,
                error: error.toString()
            })
        })
}

export const fetchCity = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(REACT_APP_BACKEND_URL + '/getList.php?nombreLista=city', { headers })
        .then(res => {
            console.log(res);
            dispatch({
                type: FETCH_CITY_SUCCESS,
                payload: {
                    city: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de ciudades: ", error);
            dispatch({
                type: FETCH_CITY_ERROR,
                error: error.toString()
            })
        })
}

export const fetchChangesTypes = (params) => (dispatch) => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_NOV_INVENTARIO')
        .then(res => {
            dispatch({
                type: FETCH_CHANGES_TYPES_SUCCESS,
                payload: {
                    changes: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista SUSI de tipo de novedad: ", error);
            dispatch({
                type: FETCH_CHANGES_TYPES_ERROR,
                error: error.toString()
            })
        })
}

export const fetchMissingReasons = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list?nombreLista=CORE_TIP_NOV_INVENTARIO');       
    
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_TIP_NOV_INVENTARIO')
        .then(res => {
      //      console.log(res.data)
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_MISSING_REASONS_SUCCESS,
                payload: {
                    missing_reasons: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_MISSING_REASONS_ERROR,
                error: error.toString()
            })
        })
}

export const fetchSurplusAssetTypes = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    //dispatch(FETCH_ASSETS_SURPLUS_TYPELIST_REQUEST)
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_TIP_BIEN_INVEN')
        .then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS,
                payload: {
                    types: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_ASSETS_SURPLUS_TYPELIST_ERROR,
                error: error.toString()
            })
        })
}

export const fetchTypeTransact = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type:FETCH_TYPE_TRANSACT_TYPELIST_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_TIPO_TRAMITE').then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS,
                payload: {
                    types: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_TYPE_TRANSACT_TYPELIST_ERROR,
                error: error.toString()
            })
        })
}

export const fetchTypeDocument = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type: FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_TIPO_DOC_REPO').then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS,
                payload: {
                    types: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_TYPE_DOCUMENT_TYPELIST_ERROR,
                error: error.toString()
            })
        })
}

export const fetchTypeReplacementStatus = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type: FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_ESTADO_REPO')
        .then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS,
                payload: {
                    types: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR,
                error: error.toString()
            })
        })
}

export const fetchRazonReimpresionPlaca = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type: FETCH_REPRINT_REASON_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/list?nombreLista=CORE_RAZ_IMPRE_PLACA')
        .then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_REPRINT_REASON_SUCCESS,
                payload: {
                    reasons: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
            dispatch({
                type: FETCH_REPRINT_REASON_ERROR,
                error: error.toString()
            })
        })
}

export const fetchTransactTypeDocument = () => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type: FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/TransactTypesDocuments').then(res => {
            dispatch({
                type: FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS,
                payload: {
                    types: res.data.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista tipos de documentos", error);
            dispatch({
                type: FETCH_TRANSACT_TYPES_DOCUMENT_ERROR,
                error: error.toString()
            })
        })
}